import React, {useState, useEffect} from "react";
import PasswordChecklist from "react-password-checklist";

const PasswordValidation = ({
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  setIsPasswordValid,
  customClasses = {},
  checklistStyles,
}) => {
  const [isTypingPassword, setIsTypingPassword] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // Default classes if no custom classes are passed
  const inputClass = customClasses.input || "";
  const checklistStyle = checklistStyles || {
    marginTop: "10px",
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "5px",
    color: "black",
  };
  const iconStyle = customClasses.icon || {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    color: "black",
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const passwordValid = password.length >= 8 && // Example check for min length
      /[A-Z]/.test(password) && // Uppercase letter
      /\d/.test(password) && // Number
      /[!@#$%^&*(),.?":{}|<>]/.test(password); // Special character

    setIsPasswordValid(passwordValid); // Update the parent state
  }, [password, setIsPasswordValid]);

  return (
    <>
      <div className={`mb-2 text-${checklistStyle.color}`}>
        Password<span className="text-danger">*</span>
      </div>
      <div className="col-md mb-2 position-relative">
        <input
          name="password"
          type={showPassword ? "text" : "password"}
          className={`form-control ${inputClass}`}
          placeholder="Password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onFocus={() => setIsTypingPassword(true)}
          onBlur={() => setIsTypingPassword(false)}
          required
        />
        <i
          className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
          style={{...iconStyle}}
          onClick={toggleShowPassword}
        />
      </div>
      {isTypingPassword && (
        <div className="password-checklist-tooltip">
          <PasswordChecklist
            rules={["minLength", "capital", "number", "specialChar"]}
            minLength={8}
            value={password}
            onChange={() => {}}
            messages={{
              minLength: "Password must be at least 8 characters.",
              capital: "Password must contain at least one uppercase letter.",
              number: "Password must contain at least one number.",
              specialChar:
                "Password must contain at least one special character.",
            }}
            style={{...checklistStyle}}
          />
        </div>
      )}

      <div className="mb-3">
      <div className={`mb-2 text-${checklistStyle.color}`}>
          Confirm Password<span className="text-danger">*</span>
        </div>
        <div className="col-md mb-2 position-relative">
          <input
            name="confirmPassword"
            type={showPassword ? "text" : "password"}
            className={`form-control ${inputClass}`}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setConfirmPasswordTouched(true);
            }}
            required
          />
          <i
            className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
            style={{
              ...iconStyle, // Merge custom checklist styles
            }}
            onClick={toggleShowPassword}
          />
        </div>
        {confirmPasswordTouched && (
          <>
            {!confirmPassword ? (
              <div className="text-danger">Re-enter the password</div>
            ) : confirmPassword !== password ? (
              <div className="text-danger">Password mismatch</div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default PasswordValidation;
