import React, {useState} from "react";
import axios from "axios";
import Spinner from "../../components/spinner";
import whiteLabel from "../../assets/whitelabelConfig";
import {formatKeys} from "./utils/utils";
import ENDPOINTS from "../../common/endpoints";
import {encryptPassword} from "../../common/helpers/utils/PasswordHelper";
import PasswordValidation from "../../common/components/PasswordValidation";
import { toast } from "react-toastify";
import {Modal} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Profile = ({user}) => {
  const [showModal, setShowModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [Message, setMessage] = useState("");

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => {
    setShowModal(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const passwordreset = (event) => {
    event.preventDefault();

    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const encryptedOldPassword = encryptPassword(oldPassword);
    const encryptedNewPassword = encryptPassword(newPassword);
    const body = {
      current_password: encryptedOldPassword,
      updated_password: encryptedNewPassword,
    };
    // console.log(body);
    axios
      .put(ENDPOINTS.ONBOARDING.updatePassword, body, {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        handleModalClose();
        toast.success(response.data.message);
      })
      .catch((error) => {
        setIsLoading(false);
        setShowErrorMessage(true);
        setMessage(error.response.data.error);
        // Hide message after 3 seconds
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 3000);
        // console.error("Error adding user:", error);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="container-fluid position-relative z-index-1 pb-5"
      style={{top: "58px"}}>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="container d-flex justify-content-center">
          <div className="card profile-card text-center mt-4 shadow-lg p-3 bg-body rounded">
            <div className="card-body text-light">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={user.logo_url ? user.logo_url : whiteLabel.MktgAI_Icon}
                  alt="client logo"
                  width="100"
                  height="100"
                  className="img-fluid rounded-circle p-1"
                />
              </div>
              <h2 className="card-title fw-bold">{user.name}</h2>
              <p className="mb-2">Role: {formatKeys(user.user_role)}</p>
              <p className="mb-3">Client: {user.client_name}</p>
              <button
                className="btn btn-primary shadow-sm"
                onClick={handleModalOpen}>
                Reset Password
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Password Reset Modal */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Password Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          ) : (
            <form onSubmit={passwordreset}>
              <div className="modal-body">
                <div className="mb-3 position-relative">
                  <label
                    htmlFor="current-password"
                    className="form-label d-flex justify-content-start text-dark">
                    Current Password<span className="text-danger">*</span>
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="current-password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Enter current password"
                    required
                  />
                  <i
                    className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "70%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "black",
                    }}
                    onClick={toggleShowPassword}
                  />
                </div>

                <PasswordValidation
                  password={newPassword}
                  setPassword={setNewPassword}
                  confirmPassword={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                  setIsPasswordValid={setIsPasswordValid}
                  isPasswordValid={isPasswordValid}
                />
                {showErrorMessage && (
                  <div className="d-flex justify-content-center mt-2 text-danger">
                  {Message}
                </div>
                )}
              </div>

              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleModalClose}>
                  Close
                </button>
                <button
                  type="submit"
                  className={`btn btn-primary ${
                    newPassword !== confirmPassword || !isPasswordValid
                      ? "disabled"
                      : ""
                  }`}
                  disabled={
                    newPassword !== confirmPassword || !isPasswordValid
                  }>
                  Reset
                </button>
              </Modal.Footer>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Profile;
