import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../../../components/spinner";

const BusinessAdaccounts = ({
  business,
  formData,
  setFormData,
  getNestedData,
  toggleSelectAll,
  highlightText,
}) => {
  const [adAccountsVisible, setAdAccountsVisible] = useState(5);
  const observer = useRef(null);
  const loaderRef = useRef(null);

  // Handle toggling selection of ad accounts
  const toggleAdAccountSelection = (adAccountId) => {
    setFormData({
      ...formData,
      selectedAdAccounts: formData.selectedAdAccounts.includes(adAccountId)
        ? formData.selectedAdAccounts.filter((id) => id !== adAccountId)
        : [...formData.selectedAdAccounts, adAccountId],
    });
  };

  useEffect(() => {
    const loadMoreAdAccounts = (entries) => {
      if (entries[0].isIntersecting) {
        // Add a delay before loading more ad accounts
        setTimeout(() => {
          setAdAccountsVisible((prev) => prev + 5);
        }, 1000);
      }
    };

    observer.current = new IntersectionObserver(loadMoreAdAccounts, {
      rootMargin: "100px",
    });

    if (loaderRef.current) {
      observer.current.observe(loaderRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      <h6 className="d-flex justify-content-between">
        Ad Accounts
        {business.ad_accounts.length > 0 && (
          <button
            className="btn btn-link text-decoration-none p-0"
            onClick={() =>
              toggleSelectAll(
                "selectedAdAccounts",
                getNestedData(business, "ad_accounts"),
                setFormData
              )
            }
          >
            {
              // Check if all ad accounts for this business are selected
              getNestedData(business, "ad_accounts").every((ad_account) =>
                formData.selectedAdAccounts.includes(ad_account.id)
              )
                ? "Deselect All"
                : "Select All"
            }
          </button>
        )}
      </h6>
      {getNestedData(business, "ad_accounts").length > 0 ? (
        getNestedData(business, "ad_accounts")
          ?.slice(0, adAccountsVisible)
          .map((adAccount) => (
            <div
              key={adAccount.id}
              className="d-flex justify-content-between align-items-center mb-2"
            >
              <label
                htmlFor={`adAccount-${adAccount.id}`}
                className="form-check-label"
              >
                <div>
                  <span>{highlightText(adAccount.name)}</span>
                  <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>
                    {highlightText(adAccount.id)}
                  </div>
                </div>
              </label>
              <div className="form-check form-switch ms-auto">
                <input
                  type="checkbox"
                  id={`adAccount-${adAccount.id}`}
                  className="form-check-input"
                  onChange={() => toggleAdAccountSelection(adAccount.id)}
                  checked={formData.selectedAdAccounts.includes(adAccount.id)}
                  style={{
                    transform: "scale(1.5)",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))
      ) : (
        <p>No Ad Accounts available.</p>
      )}
      
      {business.ad_accounts.length > adAccountsVisible && (
        <div ref={loaderRef}>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default BusinessAdaccounts;
