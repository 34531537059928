import config from '../config.json';

const ENDPOINTS = {
  ASSET:{
    getAssets: `${config.serviceUrl}/assets/get-assets`,
    getTotalAssetCount: `${config.serviceUrl}/assets/get-total-asset-count`,
    getCount: `${config.serviceUrl}/assets/get-count`,
  },
  API_FEEDS:{
    generateAuthURI: `${config.serviceUrl}/api_feed/generate_authuri`,
    getMetaTokenData: `${config.serviceUrl}/meta_api_feed/get-token-data`,
    getPaginatedData: `${config.serviceUrl}/meta_api_feed/get-paginated-data`,
    storeAccounts: `${config.serviceUrl}/meta_api_feed/store-accounts`,
    getAccounts: `${config.serviceUrl}/meta_api_feed/get-accounts`,
    updateAccounts: `${config.serviceUrl}/meta_api_feed/update-accounts`,
  },
  PERFORMANCE_METRIC: {
    getConfig: `${config.serviceUrl}/performance-metric/get-performance-metric-config`,
    addConfig: `${config.serviceUrl}/performance-metric/upsert-performance-metric-config`,
    getFields: `${config.serviceUrl}/performance-metric/get-performance-metric-fields`,
    generateGraph: `${config.serviceUrl}/performance-metric/generate-graph-data`,
    generateMultipleGraph: `${config.serviceUrl}/performance-metric/generate-multiple-graph-data`,
    getAssetCounts: `${config.serviceUrl}/performance-metric/get-asset-counts`,
  },
  AUTH: {
    getUser: `${config.serviceUrl}/auth/get-user`,
    login: `${config.serviceUrl}/auth/login`,
    forgotPassword: `${config.serviceUrl}/auth/forgot-password`,
    resetPassword: `${config.serviceUrl}/auth/reset-password`,
  },
  ONBOARDING:{
    addUser: `${config.serviceUrl}/onboarding/add-user`,
    updateUser: `${config.serviceUrl}/onboarding/update-user`,
    updateUserPassword: `${config.serviceUrl}/onboarding/update-user-password`,
    updatePassword:`${config.serviceUrl}/onboarding/update-password`
  },
  JOURNEY_MAP: {
    getJourneyMap: `${config.serviceUrl}/journey_maps/get-all-journey-map`,
    addJourneyMap: `${config.serviceUrl}/journey_maps/add-journey-map`,
    deleteJourneyMap: (id) => `${config.serviceUrl}/journey_maps/delete-journey-map/${id}`
  }
};

export default ENDPOINTS;
