import React, {useState} from "react";
import axios from "axios";
import config from "../../../../config.json";
import {Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import whiteLabel from "../../../../assets/whitelabelConfig";
import Spinner from "../../../../components/spinner";
import {toast} from "react-toastify";
import {capitalize} from "../../../../common/helpers/utils/DataHelper";
import {labelFormatter} from "../../../../common/helpers/utils/Formatter";

const TradeDeskPopup = ({
  formData,
  setFormData,
  isFormValid,
  SelectedPlatform,
  modalOpen,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const submit_access_token = `${config.serviceUrl}/api_feed/submit-access-token`;

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    if (isFormValid) {
      setIsLoading(true);
      const authToken = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      };
      const body = {
        media_platform: SelectedPlatform,
        token_detail: {
          ttd_auth: formData.token,
          start_date: formData.start_date,
          end_date: formData.end_date,
          performance_report_name: formData.performance_report_name,
          description: formData.description,
        },
      };
      // console.log(body);
      axios
        .post(submit_access_token, body, config)
        .then((response) => {
          setIsLoading(false);
          closeModal();
          toast.success(response.data.message);
        })
        .catch((error) => {
          setIsLoading(false);
          closeModal();
          toast.error(capitalize(labelFormatter(error.response.data.error || "")));
          // console.error(error);
        });
    } else {
      // Optionally, you can provide user feedback here
      alert("Please fill in all required fields.");
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleDateChange = (date, field) => {
    if (date) {
      const formattedDate = `${date.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })}`;
      setFormData((prevState) => ({
        ...prevState,
        [field]: formattedDate,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: "",
      }));
    }
  };

  return (
    <Modal show={modalOpen} onHide={closeModal} dialogClassName="modal-scroll">
      <Modal.Header closeButton>
        <Modal.Title>Token Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mb-3">
          <img
            src={whiteLabel.TradeDesk_img}
            alt="banner"
            style={{height: "26px", width: "210px"}}
          />
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="mb-2">
                <label htmlFor="token" className="form-label text-black mb-0">
                  Enter Token:
                </label>
                <div>
                  <div className="sub-text" style={{fontSize: "12px"}}>
                    <a
                      href="https://partner.thetradedesk.com/v3/portal/api/doc/Authentication#ui-method-create"
                      target="_blank"
                      rel="noreferrer"
                      title="Click here to view steps"
                      className="mx-1"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}>
                      Steps to generate Token
                    </a>
                  </div>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="token"
                name="token"
                placeholder="Enter token"
                value={formData.token}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="d-flex mb-3">
              <div className="startdate mx-2">
                Start Date:
                <i
                  className="bi bi-info-circle ms-1"
                  title="Start Date for the Token"
                  style={{cursor: "pointer"}}></i>
                <DatePicker
                  className="form-control border-secondary p-1 mx-1"
                  selected={
                    formData.start_date ? new Date(formData.start_date) : null
                  }
                  onChange={(date) => handleDateChange(date, "start_date")}
                  placeholderText="Start Date"
                  dateFormat="MMM dd, yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  maxDate={
                    formData.asset_end_date ? new Date(formData.end_date) : null
                  }
                  required={true}
                />
              </div>
              <div className="enddate mx-2">
                End Date:
                <i
                  className="bi bi-info-circle ms-1"
                  title="Expiry Date for the Token"
                  style={{cursor: "pointer"}}></i>
                <DatePicker
                  className="form-control p-1 mx-1"
                  selected={
                    formData.end_date ? new Date(formData.end_date) : null
                  }
                  onChange={(date) => handleDateChange(date, "end_date")}
                  placeholderText="End Date"
                  dateFormat="MMM dd, yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  minDate={
                    formData.start_date ? new Date(formData.start_date) : null
                  }
                  required={true}
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="performance_report_name"
                className="form-label text-black">
                Performance Report Name:
                <i
                  className="bi bi-info-circle ms-2"
                  title="Performance report name which will be generated by TradeDesk Dashboard
                      and used to show performance data"
                  style={{cursor: "pointer"}}></i>
              </label>
              <input
                type="text"
                className="form-control"
                id="performance_report_name"
                name="performance_report_name"
                placeholder="Please add performance report name here"
                value={formData.performance_report_name}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label text-black">
                Description / Notes:
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                placeholder="Please add description here"
                value={formData.description}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary me-2"
                onClick={closeModal}>
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TradeDeskPopup;
