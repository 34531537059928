import React from "react";
import { toggleInstagramSelection } from "../../utils/MetaPopup_Helper";

const BusinessPrimaryPage = ({
  business,
  formData,
  setFormData,
  highlightText,
}) => {
  // Handle toggling selection of primary pages
  const togglePrimaryPageSelection = (primaryPageId) => {
    const isSelected = formData.selectedPrimaryPage.includes(primaryPageId);

    setFormData({
      ...formData,
      selectedPrimaryPage: isSelected
        ? formData.selectedPrimaryPage.filter((id) => id !== primaryPageId)
        : [...formData.selectedPrimaryPage, primaryPageId],
      selectedPages: isSelected
        ? formData.selectedPages.filter((id) => id !== primaryPageId) // Remove page from selectedPages when deselecting from primaryPages
        : formData.selectedPages.includes(primaryPageId)
        ? formData.selectedPages
        : [...formData.selectedPages, primaryPageId],
    });
  };

  return (
    <>
      <h6 className="d-flex justify-content-between mt-2">Primary Page</h6>
      {business.primary_page ? (
        <div className="form-check ps-0">
          <div className="d-flex justify-content-between w-100">
            <label
              htmlFor={`primaryPage-${business.primary_page.id}`}
              className="form-check-label"
            >
              <div>
                <span>{highlightText(business.primary_page.name)}</span>
                <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>
                  {highlightText(business.primary_page.id)}
                </div>
              </div>
            </label>
            <div className="form-check form-switch ms-auto">
              <input
                type="checkbox"
                id={`primaryPage-${business.primary_page.id}`}
                className="form-check-input"
                onChange={() =>
                  togglePrimaryPageSelection(business.primary_page.id)
                }
                checked={formData.selectedPrimaryPage.includes(
                  business.primary_page.id
                )}
                style={{
                  transform: "scale(1.5)",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          {business.primary_page?.connected_instagram_account && (
            <div className="ms-2">
              <h6 className="mb-0">Connected Instagram Account</h6>
              <div
                key={business.primary_page.connected_instagram_account.id}
                className="d-flex justify-content-between w-100 ms-2"
              >
                <label
                  htmlFor={`instagram-${business.primary_page.connected_instagram_account.id}`}
                  className="form-check-label"
                >
                  <div>
                    <span>
                      {" "}
                      {highlightText(
                        business.primary_page.connected_instagram_account
                          .username
                      )}
                    </span>
                    <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>
                      {highlightText(
                        business.primary_page.connected_instagram_account.id
                      )}
                    </div>
                  </div>
                </label>
                <div className="form-check form-switch ms-auto">
                  <input
                    type="checkbox"
                    id={`instagram-${business.primary_page.connected_instagram_account.id}`}
                    className="form-check-input me-3"
                    onChange={() =>
                      toggleInstagramSelection(
                        business.primary_page.connected_instagram_account.id,
                        formData,
                        setFormData
                      )
                    }
                    checked={formData.selectedInstagramAccounts.includes(
                      business.primary_page.connected_instagram_account.id
                    )}
                    style={{
                      transform: "scale(1.5)",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p>No Primary Page available.</p>
      )}
    </>
  );
};

export default BusinessPrimaryPage;
