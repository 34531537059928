// src/hooks/useCustomDispatch.js
import { useDispatch } from "react-redux";
import {
  setSelectedCategory,
  setSelectedPlatform,
  setSelectedType,
  setSelectedStatus,
  setSelectedPerformance,
  setSelectedSortBy,
} from "../../../redux/actions";
import { ASSET_STATUS_MAPPING, ASSET_TYPE_MAPPING, PLATFORM_MAPPING } from "./constants";

const useCustomDispatch = () => {
  const dispatch = useDispatch();

  const handleCategoryChange = (category) => {
    if (Array.isArray(category)) {
      category.forEach((item) => {
        dispatch(setSelectedCategory(item, false));
      });
    } else {
      dispatch(setSelectedCategory(category, true));
    }
  };

  const handlePlatformsChange = (platform) => {
    if (Array.isArray(platform)) {
      platform.forEach((item) => {
        let updatedPlatform = PLATFORM_MAPPING[item] || item;
        dispatch(setSelectedPlatform(updatedPlatform, false));
      });
    } else {
      dispatch(setSelectedPlatform(platform, true));
    }
  };

  const handleTypesChange = (type) => {
    if (Array.isArray(type)) {
      type.forEach((item) => {
        let updatedType = ASSET_TYPE_MAPPING[item] || item;
        dispatch(setSelectedType(updatedType, false));
      });
    } else {
      dispatch(setSelectedType(type, true));
    }
  };

  const handleStatusChange = (status) => {
    if (Array.isArray(status)) {
      status.forEach((item) => {
        let updatedStatus = ASSET_STATUS_MAPPING[item] || item;
        dispatch(setSelectedStatus(updatedStatus, false));
      });
    } else {
      dispatch(setSelectedStatus(status, true));
    }
  };

  const handlePerformanceChange = (performance_value) => {
    if (Array.isArray(performance_value)) {
      performance_value.forEach((item) => {
        dispatch(setSelectedPerformance(item, false));
      });
    } else {
      dispatch(setSelectedPerformance(performance_value, true));
    }
  };

  const handleSortByChange = (sortBy) => {
    if (sortBy === "asset_created_time") {
      sortBy = "Latest";
    }
    // TODO: Remove below code when all sortby filters are present
    dispatch(setSelectedSortBy(sortBy));
  };

  return {
    handleCategoryChange,
    handlePlatformsChange,
    handleTypesChange,
    handleStatusChange,
    handlePerformanceChange,
    handleSortByChange,
  };
};

export default useCustomDispatch;
