import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import config from "../../../../config.json";
import {formatDateForBackend, renderBadge} from "../../utils/utils";
import {excludedPlatformSpecificKeys, topPlatformKeys} from "./DetailsConfig";
import {formatKeys} from "../../utils/utils";
import localisable from './../../../../common/constants/localisable';
import { dateFormatter, isValidDate } from "../../../../common/helpers/utils/DateTime";
import { FULL_GMT_DISPLAY_DATE_FORMAT } from "../../../../common/constants/constants";
import { toast } from "react-toastify";

const DetailsMeta = ({
  assetDetail,
  formData,
  setFormData,
  getassetDetail,
  handleChange,
  formValid,
  setFormValid,
  setisLoading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const ExcludedKeys =
    excludedPlatformSpecificKeys[
      assetDetail.media_platform === "Non_Digital"
        ? assetDetail.media_platform
        : assetDetail.sub_media_platform
    ] || [];
  const platform_specific_data =
    assetDetail.platform_specific_data &&
    typeof assetDetail.platform_specific_data === "object"
      ? assetDetail.platform_specific_data
      : {};
  const platformTopKeys = topPlatformKeys[assetDetail.sub_media_platform] || [];
  const orderedKeys = [];

  const filteredSpecificData = Object.entries(platform_specific_data).filter(
    ([key]) => !ExcludedKeys.includes(key)
  );

  // Add topPerformanceKeys in specified order if they exist in filteredInsights
  if (platformTopKeys.length > 0) {
    // Add top keys in specified order if they exist in filteredInsights
    platformTopKeys.forEach((topKey) => {
      const index = filteredSpecificData.findIndex(([key]) => key === topKey);
      if (index !== -1) {
        orderedKeys.push(filteredSpecificData[index]);
        filteredSpecificData.splice(index, 1); // Remove the key from filteredInsights to avoid duplication
      }
    });
  }

  // Add the remaining keys in their original order
  orderedKeys.push(...filteredSpecificData);

  const handleDateChange = (date, id) => {
    const value = date ? date.toISOString() : null;
    handleChange({id, value}, true);
    document.getElementById("form-id").checkValidity(); // Check validity of the form after date change
    setFormValid(document.getElementById("form-id").checkValidity());
  };

  const UpdateDetails = (e) => {
    e.preventDefault();
    setisLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      asset_id: formData.id,
      file_name: formData.asset_name,
      common_name: formData.platform_specific_data.common_name,
      performance_value: formData.performance_value,
      status: formData.status,
      channel: formData.sub_media_platform,
      filepath: formData.platform_specific_data.filepath,
      file_owner: formData.platform_specific_data.file_owner,
      encoding: formData.platform_specific_data.encoding,
      audio: formData.platform_specific_data.audio,
      duration: formData.platform_specific_data.duration,
      color_range: formData.platform_specific_data.color_range,
      asset_name: formData.asset_name,
      asset_type: formData.type,
      ad_start_date: formData.asset_start_date
        ? formatDateForBackend(formData.asset_start_date)
        : null,
      ad_end_date: formData.asset_end_date
        ? formatDateForBackend(formData.asset_end_date)
        : null,
      post_content: formData.body
    };
    // console.log("Submitting updated details:", body);
    axios
      .post(
        `${config.serviceUrl}/non-digital/update-non-digital-asset`,
        body,
        configuration
      )
      .then((response) => {
        getassetDetail();
        setTimeout(() => {
          setisLoading(false);
        }, 5000);
        toast.success(response.data.message);
      })
      .catch((error) => {
        setisLoading(false);
        toast.success(error.response.data.error);
        // console.error("Error uploading data:", error);
      });
  };

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setFormValid(false);
    setFormData(assetDetail);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h3 className="d-flex justify-content-start">
          Asset Meta Data/Details
        </h3>
        {assetDetail.media_platform === "Non_Digital" && (
          <button
            className="btn btn-primary ms-auto mb-1"
            onClick={handleShowModal}>
            <i className="bi bi-pencil-square"></i> Edit
          </button>
        )}
      </div>
      <div>
        <b>Last Updated:</b>{" "}
        {assetDetail.updated_time ? assetDetail.updated_time : localisable.notAvailable}
      </div>
      <hr />
      <div className="card-body">
        <div className="d-flex justify-content-center">
          <table className="d-flex container-fluid justify-content-center p-0">
            <tbody className="container-fluid d-flex text-break p-0">
              <div className="col-6 border-end border-secondary px-3">
                <h6>General Information</h6>
                <hr className="m-0 mb-2" />
                <tr className="d-flex mb-2">
                  <td className="col-6 text-end">
                    <b>Performance:</b>
                  </td>
                  <td className="col-6 mx-2">
                    {assetDetail.performance_value ? (
                      renderBadge(assetDetail.performance_value)
                    ) : (
                      <span
                        className="badge ms-1"
                        style={{backgroundColor: "#808080", color: "black"}}>
                        Unknown
                      </span>
                    )}
                  </td>
                </tr>
                <tr className="d-flex mb-2">
                  <td className="col-6 text-end">
                    <b>Status:</b>
                  </td>
                  <td className="col-6 mx-2">
                    {assetDetail.status ? assetDetail.status : "Unknown"}
                  </td>
                </tr>
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>Channel:</b>
                  </td>
                  <td className="col-6 mx-2">
                    <i className="bi bi-diagram-2-fill" aria-hidden="true"></i>{" "}
                    {
                      assetDetail.sub_media_platform &&
                        assetDetail.sub_media_platform
                          .replace(/_/g, " ") // Replace underscores with spaces
                          .split(" ") // Split the string into words
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          ) // Capitalize the first letter of each word
                          .join(" ") // Join the words back together with spaces
                    }
                  </td>
                </tr>
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>Category:</b>
                  </td>
                  <td className="col-6 mx-2">{assetDetail.category}</td>
                </tr>
                <h6>Dates</h6>
                <hr className="m-0 mb-2" />
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>Asset Creation Date:</b>
                  </td>
                  <td className="col-6 mx-2"> {assetDetail.created_time}</td>
                </tr>
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>Effective Start Date:</b>
                  </td>
                  <td className="col-6 mx-2">
                    {" "}
                    {assetDetail.asset_start_date || localisable.notAvailable }
                  </td>
                </tr>
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>Effective End Date:</b>
                  </td>
                  <td className="col-6 mx-2">
                    {" "}
                    {assetDetail.asset_end_date || localisable.notAvailable}
                  </td>
                </tr>
                <h6>Media Information</h6>
                <hr className="m-0 mb-2" />
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>File Owner:</b>
                  </td>
                  <td className="col-6 mx-2">
                    {" "}
                    <i
                      className="bi bi-person-circle"
                      aria-hidden="true"></i>{" "}
                    {assetDetail.media_platform === "Non_Digital"
                      ? assetDetail.platform_specific_data.file_owner
                      : assetDetail.asset_owner}
                  </td>
                </tr>
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>File Size:</b>
                  </td>
                  <td className="col-6 mx-2">
                    {assetDetail.file_size
                      ? assetDetail.file_size
                      : localisable.notAvailable}
                  </td>
                </tr>
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>File Type:</b>
                  </td>
                  <td className="col-6 mx-2">
                    <i
                      className={`bi ${
                        assetDetail.type === "video"
                          ? "bi-camera-video-fill"
                          : assetDetail.type === "image"
                          ? "bi-card-image"
                          : assetDetail.type === "pdf"
                          ? "bi-filetype-pdf"
                          : assetDetail.type === "audio"
                          ? "bi bi-volume-up-fill"
                          : assetDetail.type === "webpage"
                          ? "bi bi-filetype-html"
                          : assetDetail.type === "link"
                          ? "bi bi-link-45deg"
                          : "bi bi-file-text-fill"
                      } me-1`}></i>
                    {assetDetail.type &&
                      assetDetail.type.charAt(0).toUpperCase() +
                        assetDetail.type.slice(1)}
                  </td>
                </tr>
                {(assetDetail.type !== "blog" || "link") &&
                  assetDetail.file_extension && (
                    <tr className="d-flex mb-3">
                      <td className="col-6 text-end">
                        <b>File Extension:</b>
                      </td>
                      <td className="col-6 mx-2">
                        <i
                          className="bi bi-file-earmark"
                          aria-hidden="true"></i>
                        {assetDetail.file_extension}
                      </td>
                    </tr>
                  )}
                 <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>File Dimensions:</b>
                  </td>
                  <td className="col-6 mx-2" nowrap="true">
                    {assetDetail.file_dimensions
                      ? `${assetDetail.file_dimensions} px`
                      : localisable.notAvailable}
                  </td>
                </tr>
                {assetDetail.media_platform === "Non_Digital" && (
                  <>
                    <tr className="d-flex mb-3">
                      <td className="col-6 text-end">
                        <b>File Name:</b>
                      </td>
                      <td className="col-6 mx-2">{assetDetail.asset_name}</td>
                    </tr>
                    <tr className="d-flex mb-3">
                      <td className="col-6 text-end">
                        <b>Common Name:</b>
                      </td>
                      <td className="col-6 mx-2">
                        {assetDetail.platform_specific_data.common_name
                          ? assetDetail.platform_specific_data.common_name
                          : localisable.notAvailable}
                      </td>
                    </tr>
                  </>
                )}
                {assetDetail.media_platform === "Non_Digital" && assetDetail.platform_specific_data  && (
                  <>
                  <tr className="d-flex mb-3">
                    <td className="col-6 text-end">
                      <b>File Path:</b>
                    </td>
                    <td className="col-6 mx-2">
                      {assetDetail.platform_specific_data.filepath
                        ? assetDetail.platform_specific_data.filepath
                        : localisable.notAvailable}
                    </td>
                  </tr>
                    <tr className="d-flex mb-3">
                      <td className="col-6 text-end">
                        <b>Encoding:</b>
                      </td>
                      <td className="col-6 mx-2">
                        {assetDetail.platform_specific_data.encoding ||
                          localisable.notAvailable}
                      </td>
                    </tr>
                    <tr className="d-flex mb-3">
                      <td className="col-6 text-end">
                        <b>Audio:</b>
                      </td>
                      <td className="col-6 mx-2">
                        {assetDetail.platform_specific_data.audio ||
                          localisable.notAvailable}
                      </td>
                    </tr>
                    <tr className="d-flex mb-3">
                      <td className="col-6 text-end">
                        <b>Duration:</b>
                      </td>
                      <td className="col-6 mx-2">
                        {assetDetail.platform_specific_data.duration ||
                          localisable.notAvailable}
                      </td>
                    </tr>
                    <tr className="d-flex mb-3">
                      <td className="col-6 text-end">
                        <b>Color Range:</b>
                      </td>
                      <td className="col-6 mx-2">
                        {assetDetail.platform_specific_data.color_range ||
                          localisable.notAvailable}
                      </td>
                    </tr>
                  </>
                )}
                {assetDetail.shareable_link && (
                  <>
                    <tr className="d-flex mb-3">
                      <td className="col-6 text-end">
                        <b>Post Url:</b>
                      </td>
                      <td className="col-6 mx-2">
                        <a
                          className="text-info"
                          href={
                            assetDetail.shareable_link.startsWith("http")
                              ? assetDetail.shareable_link
                              : `http://${assetDetail.shareable_link}`
                          }
                          target="_blank"
                          rel="noopener noreferrer">
                          {assetDetail.shareable_link}
                        </a>
                      </td>
                    </tr>
                  </>
                )}
                {assetDetail.media_platform !== "Non_Digital" && (
                  <>
                  <tr className="d-flex mb-3">
                    <td className="col-6 text-end">
                      <b>Tags:</b>
                    </td>
                    <td className="col-6 mx-2">
                      {assetDetail.media_tags && assetDetail.media_tags}
                    </td>
                  </tr>
                  <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>Captions:</b>
                  </td>
                  <td className="col-6 mx-2">{assetDetail.title}</td>
                </tr>
                </>
                )}
              </div>
              <div className="col-6 px-3">
                <h6>Demographics</h6>
                <hr className="m-0 mb-2" />
                {assetDetail.demographics ? (
                  Object.entries(assetDetail.demographics).map(
                    ([key, value]) => (
                      <tr className="d-flex mb-3" key={key}>
                        <td className="col-6 text-end">
                          <b>{formatKeys(key)}:</b>
                        </td>
                        <td className="col-6 mx-2">
                          {value && value.length > 0 ? ( // Check if value exists and is not empty
                            Array.isArray(value) ? (
                              <span>
                                {key === "geo"
                                  ? value.join("; ")
                                  : value.join(", ")}
                              </span>
                            ) : (
                              <span>{value ?? localisable.notApplicableAbr}</span> // Handle plain value
                            )
                          ) : (
                            <span>{localisable.notAvailable}</span> // Display "Not Available" if data is missing
                          )}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <div className="fs-6 mb-3">{localisable.notAvailable}</div>
                )}

                <h6>Platform Specific</h6>
                <hr className="m-0 mb-2" />
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>Id:</b>
                  </td>
                  <td className="col-6 mx-2">#{assetDetail.id}</td>
                </tr>
                <tr className="d-flex mb-3">
                  <td className="col-6 text-end">
                    <b>Asset Id:</b>
                  </td>
                  <td className="col-6 mx-2">{assetDetail.asset_id}</td>
                </tr>
                {assetDetail.platform_specific_data &&
                    orderedKeys.flatMap(([key, value]) => (
                      <tr className="d-flex mb-3" key={key}>
                        <td className="col-6 text-end">
                          <b>{formatKeys(key)}:</b>
                        </td>
                        <td className="col-6 mx-2">
                          {value === null || value === "" ? (
                            localisable.notAvailable
                          ) : typeof value === "object" ? (
                            // Render object key-value pairs if value is an object
                            <div>
                              {Object.entries(value).map(
                                ([subKey, subValue]) => (
                                  <div key={subKey}>
                                    <b>{formatKeys(subKey)}:</b>{" "}
                                    {subValue ?? localisable.notAvailable}
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            // Render value directly if it is not an object
                            isValidDate(value) ? dateFormatter(value, true, FULL_GMT_DISPLAY_DATE_FORMAT, true) : value
                          )}
                        </td>
                      </tr>
                    ))}
              </div>
            </tbody>
          </table>
        </div>
      </div>
      {/* UpdateDetails Modal */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="updatedetailsLabel">
        <Modal.Header closeButton>
          <Modal.Title id="updatedetailsLabel">Edit Details</Modal.Title>
        </Modal.Header>
        <form id="form-id" onSubmit={UpdateDetails}>
          <Modal.Body>
            <div className="modal-body">
              <div className="mb-3">
                <label
                  htmlFor="file_name"
                  className="form-label d-flex justiy-content-start text-dark">
                  File Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="file_name"
                  value={assetDetail.asset_name}
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="common_name"
                  className="form-label d-flex justiy-content-start text-dark">
                  Common Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="platform_specific_data.common_name"
                  value={
                    formData.platform_specific_data
                      ? formData.platform_specific_data.common_name
                      : ""
                  }
                  onChange={handleChange}></input>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="filepath"
                  className="form-label d-flex justiy-content-start text-dark">
                  File Path:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="platform_specific_data.filepath"
                  value={
                    formData.platform_specific_data
                      ? formData.platform_specific_data.filepath
                      : ""
                  }
                  onChange={handleChange}></input>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="performance_value"
                  className="form-label d-flex justiy-content-start text-dark">
                  Performance:
                </label>
                <select
                  className="form-select"
                  id="performance_value"
                  name="performance_value"
                  value={formData.performance_value}
                  onChange={handleChange}>
                  <option value="1">Excellent</option>
                  <option value="2">Good</option>
                  <option value="3">Average</option>
                  <option value="4">Low</option>
                  <option value="5">Very Low</option>
                  <option value="0">Unknown</option>
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="status"
                  className="form-label d-flex justiy-content-start text-dark">
                  Status:
                </label>
                <select
                  className="form-select"
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}>
                  <option value="ACTIVE">Active</option>
                  <option value="PAUSED">Paused</option>
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="sub_media_platform"
                  className="form-label d-flex justiy-content-start text-dark">
                  Channel:
                </label>
                <select
                  className="form-select"
                  id="sub_media_platform"
                  name="sub_media_platform"
                  value={formData.sub_media_platform}
                  onChange={handleChange}>
                  <option value="" disabled selected>
                    Please select a channel
                  </option>
                  <option value="print">Print</option>
                  <option value="video_television">Video/Television</option>
                  <option value="audio_radio">Audio/Radio</option>
                  <option value="out_of_home_billboards">
                    Out of Home/Billboards
                  </option>
                  <option value="others">Others</option>
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="date"
                  className="form-label d-flex justiy-content-start text-dark">
                  Date:
                </label>
                <div className="d-flex">
                  <div className="startdate mx-2">
                    Start Date:
                    <DatePicker
                      className="form-control border-secondary p-1 mx-1"
                      selected={
                        formData.asset_start_date
                          ? new Date(formData.asset_start_date)
                          : null
                      }
                      onChange={(date) =>
                        handleDateChange(date, "asset_start_date")
                      }
                      placeholderText="Start Date"
                      dateFormat="MMM dd, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      maxDate={
                        formData.asset_end_date
                          ? new Date(formData.asset_end_date)
                          : null
                      }
                    />
                  </div>
                  <div className="enddate mx-2">
                    End Date:
                    <DatePicker
                      className="form-control p-1 mx-1"
                      selected={
                        formData.asset_end_date
                          ? new Date(formData.asset_end_date)
                          : null
                      }
                      onChange={(date) =>
                        handleDateChange(date, "asset_end_date")
                      }
                      placeholderText="End Date"
                      dateFormat="MMM dd, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      minDate={
                        formData.asset_start_date
                          ? new Date(formData.asset_start_date)
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="encoding"
                  className="form-label d-flex justiy-content-start text-dark">
                  Encoding:
                </label>
                <select
                  className="form-select"
                  id="platform_specific_data.encoding"
                  name="encoding"
                  value={
                    formData.platform_specific_data
                      ? formData.platform_specific_data.encoding
                      : ""
                  }
                  onChange={handleChange}>
                  <option value="None">None</option>
                  <option value="H.264">H.264, AAC</option>
                  <option value="HD">HD (1-1-1)</option>
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="platform_specific_data.color_range"
                  className="form-label d-flex justiy-content-start text-dark">
                  Color Range:
                </label>
                <select
                  className="form-select"
                  id="platform_specific_data.color_range"
                  name="color_range"
                  value={
                    formData.platform_specific_data
                      ? formData.platform_specific_data.color_range
                      : ""
                  }
                  onChange={handleChange}>
                  <option value="CMYK">CMYK</option>
                  <option value="Hex">Hex</option>
                  <option value="RGB">RGB</option>
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="audio"
                  className="form-label d-flex justiy-content-start text-dark">
                  Audio:
                </label>
                <select
                  className="form-select"
                  id="platform_specific_data.audio"
                  name="audio"
                  value={
                    formData.platform_specific_data
                      ? formData.platform_specific_data.audio
                      : ""
                  }
                  onChange={handleChange}>
                  <option value="None">None</option>
                  <option value="mono">Mono</option>
                  <option value="stereo">Stereo</option>
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="duration"
                  className="form-label d-flex justiy-content-start text-dark">
                  Duration:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="platform_specific_data.duration"
                  value={
                    formData.platform_specific_data
                      ? formData.platform_specific_data.duration
                      : ""
                  }
                  onChange={handleChange}></input>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="body"
                  className="form-label d-flex justiy-content-start text-dark">
                  META/POST CONTENT:
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="body"
                  value={formData.body ? formData.body : ""}
                  onChange={handleChange}></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            {formValid ? (
              <Button variant="primary" type="submit">
                Save
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Save
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default DetailsMeta;
