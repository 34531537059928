import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../../../components/spinner";
import { toggleInstagramSelection } from "../../utils/MetaPopup_Helper";

const BusinessPages = ({
  business,
  formData,
  setFormData,
  getNestedData,
  toggleSelectAll,
  highlightText,
}) => {
  const [pagesVisible, setPagesVisible] = useState(5);
  const observer = useRef(null);
  const loaderRef = useRef(null);

  // Handle toggling selection of pages
  const togglePageSelection = (pageId) => {
    const isSelectedInPages = formData.selectedPages.includes(pageId);

    setFormData({
      ...formData,
      selectedPages: isSelectedInPages
        ? formData.selectedPages.filter((id) => id !== pageId)
        : [...formData.selectedPages, pageId],
      selectedPrimaryPage: isSelectedInPages
        ? formData.selectedPrimaryPage.filter((id) => id !== pageId)
        : formData.selectedPrimaryPage.includes(pageId)
        ? formData.selectedPrimaryPage
        : [...formData.selectedPrimaryPage, pageId],
    });
  };

  useEffect(() => {
    const loadMorePages = (entries) => {
      if (entries[0].isIntersecting) {
        setTimeout(() => {
          setPagesVisible((prev) => prev + 5);
        }, 1000);
      }
    };

    observer.current = new IntersectionObserver(loadMorePages, {
      rootMargin: "100px",
    });

    if (loaderRef.current) {
      observer.current.observe(loaderRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      <h6 className="d-flex justify-content-between">
        Pages
        {getNestedData(business, "pages").length > 0 && (
          <button
            className="btn btn-link text-decoration-none p-0"
            onClick={() =>
              toggleSelectAll(
                "selectedPages",
                getNestedData(business, "pages"),
                setFormData,
                "connected_instagram_account"
              )
            }
          >
            {
              // Check if all pages for this business are selected
              getNestedData(business, "pages").every((page) =>
                formData.selectedPages.includes(page.id)
              )
                ? "Deselect All"
                : "Select All"
            }
          </button>
        )}
      </h6>
      {getNestedData(business, "pages").length > 0 ? (
        getNestedData(business, "pages")
          ?.slice(0, pagesVisible)
          .map((page) => (
            <div key={page.id} className="form-check ps-0 mt-2">
              <div className="d-flex justify-content-between w-100">
                <label htmlFor={`page-${page.id}`} className="form-check-label">
                  <div>
                    <span> {highlightText(page.name)}</span>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        color: "#6c757d",
                      }}
                    >
                      {highlightText(page.id)}
                    </div>
                  </div>
                </label>
                <div className="form-check form-switch ms-auto">
                  <input
                    type="checkbox"
                    id={`page-${page.id}`}
                    className="form-check-input"
                    onChange={() => togglePageSelection(page.id)}
                    checked={formData.selectedPages.includes(page.id)}
                    style={{
                      transform: "scale(1.5)",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>

              {page.connected_instagram_account && (
                <div className="ms-2">
                  <h6 className="mb-0">Connected Instagram Account</h6>
                  <div className="d-flex justify-content-between w-100 ms-2">
                    <label
                      htmlFor={`instagram-${page.connected_instagram_account.id}`}
                      className="form-check-label"
                    >
                      <div>
                        <span>
                          {" "}
                          {highlightText(
                            page.connected_instagram_account.username
                          )}
                        </span>
                        <div
                          style={{
                            fontSize: "0.8rem",
                            color: "#6c757d",
                          }}
                        >
                          {highlightText(page.connected_instagram_account.id)}
                        </div>
                      </div>
                    </label>
                    <div className="form-check form-switch ms-auto">
                      <input
                        type="checkbox"
                        id={`instagram-${page.connected_instagram_account.id}`}
                        className="form-check-input me-3"
                        onChange={() =>
                          toggleInstagramSelection(
                            page.connected_instagram_account.id, formData, setFormData
                          )
                        }
                        checked={formData.selectedInstagramAccounts.includes(
                          page.connected_instagram_account.id
                        )}
                        style={{
                          transform: "scale(1.5)",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="border-bottom border-2 border-light mt-2"></div>
            </div>
          ))
      ) : (
        <p>No Pages available.</p>
      )}

      {business.pages.length > pagesVisible && (
        <div ref={loaderRef}>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default BusinessPages;
