import { useState, useMemo } from "react";
import axios from "axios";
import ENDPOINTS from "../../../../common/endpoints";
import { getAPIConfig } from "../../../../common/utils";

const useFetchMetaAccounts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountsData, setAccountsData] = useState({});
  const [error, setError] = useState(null);
  const apiHeaderConfig = useMemo(() => getAPIConfig(), []);

  const fetchAccounts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        ENDPOINTS.API_FEEDS.getAccounts,
        apiHeaderConfig
      );
      setAccountsData(response.data);
      return response.data
    } catch (err) {
      setError(err.message || "An error occurred while fetching accounts.");
      return null; // Return null if an error occurs
    } finally {
      setIsLoading(false);
    }
  };  

  return {
    fetchAccounts,
    accountsData,
    isLoading,
    error,
  };
};

export default useFetchMetaAccounts;
