import React, {useState, useEffect, useRef} from "react";
import Spinner from "../../../components/spinner";
import { toast } from "react-toastify";
import FileNamePath from "./FileNamePath";
import FileStatus from "./FileStatus";
import FileOwnership from "./FileOwnership";
import FileChannel from "./FileChannel";
import FileMeta from "./FileMeta";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import config from "../../../config.json";

const AssetUpload = (props) => {
  const [currentView, setCurrentView] = useState("Upload Asset");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [Message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    file: null,
    thumbnail: null,
    channel: "",
    performance_value: 0,
    status: "active",
    file_name: "",
    common_name: "",
    description: "",
    creation_date: "",
    asset_start_date: "",
    asset_end_date: "",
    filepath: "",
    file_owner: props.user.name,
    asset_type: "",
    extension: "",
    color_range: "",
    size: "",
    width: "",
    height: "",
    encoding: "",
    audio: "",
    duration: "",
    postContent: "",
    // performanceMeasurement: "",
    // documents: null,
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    const newValue = name === "performance_value" ? parseInt(value, 10) : value;
    setFormData({...formData, [name]: newValue});
  };

  const handleFileChange = (e) => {
    setShowErrorMessage(false);
    let file = e.target.files[0];

    if (!file) return; // If no file selected, exit
    const {id} = e.target;
    const file_name = file.name;
    const extension = file_name.split('.').pop().toLowerCase();
    const validExtensions = [
      'jpg', 'jpeg', 'png', 'webp', 
      'mp4', 'gif', 'mp3', 'pdf'
    ];
    const validThumbnailExtensions = ['jpg', 'jpeg', 'png', 'webp']
  
    // Validate filename (no spaces allowed)
    if (file_name.includes(' ')) {
      showError("File name should not contain spaces.");
      resetFileInput(e, id);
      return;
    }
  
    // Validate file extension
    if (id === "fileInput" && !validExtensions.includes(extension)) {
      showError("File type not supported.");
        resetFileInput(e, id);
      return;
    }else if (id === "thumbnail" && !validThumbnailExtensions.includes(extension)){
      showError("File type not supported.");
      resetFileInput(e, id);
    }
  
    const size = file.size;
    let width = "";
    let height = "";
    let asset_type = '';
  
    // Determine asset type based on extension
    if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
      asset_type = 'image';
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          width = img.width;
          height = img.height;
          // Update form data with image dimensions
          updateFormData(file, id, {extension, size, file_name, asset_type, width, height});
        };
      };
      reader.readAsDataURL(file);
    } else if (['mp4', 'gif'].includes(extension)) {
      asset_type = 'video';
    } else if (extension === 'mp3') {
      asset_type = 'audio';
    } else if (['pdf', 'doc', 'docx'].includes(extension)) {
      asset_type = 'document';
    }
  
    // Update form data
    updateFormData(file, id, {extension, size, file_name, asset_type, width, height});
  };

  // Helper function to update formData for file or thumbnail separately
  const updateFormData = (file, id, additionalData) => {
    if (id === "fileInput") {
      setFormData({
        ...formData,
        file,
        ...additionalData,
      });
    } else if (id === "thumbnail") {
      setFormData({
        ...formData,
        thumbnail: file,
      });
    }
  };
  
  const resetFileInput = (e, id) => {
    if (id === "fileInput"){
      setFormData((prevData) => ({...prevData, file: null, asset_type: "", extension: "", size: "", width: "", height: "",})); // Reset state
    }
    e.target.value = null; // Clear input value
  };

  const showError = (message) => {
    setShowErrorMessage(true);
    setMessage(message);
    setTimeout(() => setShowErrorMessage(false), 3000);
  };

  const handleDateChange = (date, field) => {
    if (date) {
      const formattedDate = `${date.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })}`;
      setFormData((prevState) => ({
        ...prevState,
        [field]: formattedDate,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: "",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if a file has been selected
    if (!fileInputRef.current.files.length) {
      // Display the custom error message
      const errorDiv = document.getElementById("customError");
      errorDiv.style.display = "block";

      // Focus on the input field
      fileInputRef.current.focus();
    } else {
      setIsLoading(true);
      const authToken = localStorage.getItem("token");
      const configuration = {
        headers: {
          Authorization: authToken,
          "Content-Type": "multipart/form-data",
        },
      };
      const formdata = new FormData();
      for (const key in formData) {
        const value = formData[key];
        if (value !== "") {
          formdata.append(key, value);
        }
      }
      // console.log(formData);
      axios
        .post(
          `${config.serviceUrl}/non-digital/upload-non-digital-asset`,
          formdata,
          configuration
        )
        .then((response) => {
          setIsLoading(false);
          handleClear();
          toast.success(response.data.message);
        })
        .catch((error) => {
          setIsLoading(false);
          handleClear();
          toast.error(error.response.data.error);
          // console.error("Error uploading data:", error);
        });
    }
  };

  const handleClear = (action) => {
    setFormData({
      file: null,
      thumbnail: null,
      channel: "",
      performance_value: 0,
      status: "active",
      file_name: "",
      common_name: "",
      description: "",
      creation_date: "",
      asset_start_date: "",
      asset_end_date: "",
      filepath: "",
      file_owner: props.user.name,
      asset_type: "",
      extension: "",
      color_range: "",
      size: "",
      width: "",
      height: "",
      encoding: "",
      audio: "",
      duration: "",
      postContent: "",
    });
    if (action === "clear_file") {
      document.getElementById("fileInput").value = "";
      document.getElementById("thumbnail").value = "";
    }
  };

  const [objectUrl, setObjectUrl] = useState(null);

  // Create object URL when formData.file changes
  useEffect(() => {
    if (formData.file) {
      const url = URL.createObjectURL(formData.file);
      setObjectUrl(url);
      return () => URL.revokeObjectURL(url); // Revoke object URL when component unmounts
    }
  }, [formData.file]);

  const formatSize = (sizeInBytes) => {
    if (!sizeInBytes) return ""; // Return an empty string if no size

    const kb = sizeInBytes / 1024; // Convert bytes to KB
    const mb = kb / 1024; // Convert KB to MB
    const gb = mb / 1024; // Convert MB to GB

    // Determine the appropriate unit and format the size
    if (gb >= 1) {
      return `${gb.toFixed(2)} GB`;
    } else if (mb >= 1) {
      return `${mb.toFixed(2)} MB`;
    } else {
      return `${kb.toFixed(2)} KB`;
    }
  };

  return (
    <div className="d-flex flex-column">
      <div
        className={
          "m-0 p-0 inner-nav position-fixed fixed-top row justify-content-end" +
          (props.isNavbarVisible ? " nav-up" : " nav-down")
        }
        style={{zIndex: "10"}}>
        {props.isSideBar && <div className="col-3 col-md-3 col-lg-2"></div>}
        <div
          className={`${
            props.isSideBar ? "col-9 col-md-9 col-lg-10" : "col"
          } transition-width p-0`}>
          {/* Navigation bar */}
          <nav className="navbar navbar-expand-lg navbar-dark bg-black border border-secondary">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav col-4">
                  <li className="nav-item">
                    <button
                      className={`btn nav-link ${
                        currentView === "Upload Asset"
                          ? "active"
                          : "text-secondary"
                      }`}
                      onClick={() => setCurrentView("Upload Asset")}>
                      Upload Asset
                    </button>
                  </li>
                </ul>
                {showErrorMessage && (
                  <div className="col d-flex justify-content-start fs-5">
                    <div className="alert alert-danger p-2 m-0" role="alert">
                      {Message}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="position-relative text-white pb-4" style={{top: "125px"}}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {currentView === "Upload Asset" && (
              <div className="container">
                <form onSubmit={handleSubmit}>
                  <div className="d-flex justify-content-center">
                    <h4>Asset Details</h4>
                  </div>
                  <div className="row d-flex">
                    {/* Left Section: File Upload and Render */}
                    <div className="col-md-6">
                      <div className="bg-black py-1 px-2 rounded rounded-bottom-0 text-truncate">
                        File Name:{" "}
                        {formData.file && formData.file.name
                          ? formData.file.name
                          : null}
                      </div>
                      <div className="left-section rounded rounded-top-0 py-2 px-2">
                        <div className="mb-3 col-md-8">
                          <label
                            htmlFor="fileInput"
                            className="form-label text-white">
                            Upload File:
                          </label>
                          <input
                            type="file"
                            className="form-control asset-inputs"
                            id="fileInput"
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png, .webp, .mp4, .gif, .mp3, .pdf"
                            ref={fileInputRef}
                          />
                          <small className="form-text text-light">
                            File upload limit is 30 MB.
                          </small>
                          <div
                            id="customError"
                            className="invalid-feedback fs-6"
                            style={{display: "none"}}>
                            Please select a file.
                          </div>
                        </div>
                        {/* Render Uploaded File (if any) */}
                        <div className="mb-3">
                          {formData.file && (
                            <>
                              <h6>Uploaded File:</h6>
                              {formData.file.type.startsWith("image/") ? (
                                <img
                                  src={URL.createObjectURL(formData.file)}
                                  className="img-fluid w-100"
                                  alt="Uploaded File"
                                  style={{objectFit: "contain"}}
                                />
                              ) : formData.file.type.startsWith("video/") ? (
                                <video
                                  key={formData.file.name}
                                  controls
                                  className="w-100">
                                  <source
                                    src={URL.createObjectURL(formData.file)}
                                    type={formData.file.type}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : formData.file.type === "application/pdf" ? (
                                <iframe
                                  src={objectUrl}
                                  width="100%"
                                  height="500px"
                                  title="Uploaded PDF"
                                />
                              ) : (
                                <p>{formData.file.name}</p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Right Section: Remaining Data Fields */}
                    <div className="col-md-6 pb-4">
                      <div className="bg-black py-1 px-2 rounded rounded-bottom-0">
                        Details
                      </div>
                      <div className="right-section rounded rounded-top-0 py-2 px-2">
                        {/* FILE STATUS */}
                        <FileStatus
                          formData={formData}
                          handleChange={handleChange}
                        />
                        <hr />
                        {/* FILE NAME/PATH  */}
                        <FileNamePath
                          formData={formData}
                          handleChange={handleChange}
                          handleFileChange={handleFileChange}
                          handleDateChange={handleDateChange}
                        />
                        <hr />
                        {/* OWNERSHIP */}
                        <FileOwnership
                          formData={formData}
                          handleChange={handleChange}
                        />
                        <hr />
                        {/* CHANNEL */}
                        <FileChannel
                          formData={formData}
                          handleChange={handleChange}
                        />
                        <hr />
                        {/* FILE META */}
                        <FileMeta
                          formData={formData}
                          handleChange={handleChange}
                          formatSize={formatSize}
                        />
                        <hr />
                        <div className="row mb-3">
                          <div className="col-md-6 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => handleClear("clear_file")}>
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AssetUpload;
