import React from 'react';
import useCustomDispatch from '../utils/useCustomDispatch';
import { formatKeys } from '../utils/utils';

function Asset_Filters({
  selectedCategory,
  selectedPlatform,
  selectedType,
  selectedStatus,
  selectedPerformance,
  selectedSortBy,
  categories,
  platforms,
  types,
  Status,
  performance,
  sortBy,
  getShadeInfo,
  categoryCounts = {},
  platformCounts = {},
  typeCounts = {},
  statusCounts = {},
  performanceCounts = {},
  totalCounts = {},
  disabledPlatforms,
}) {
  const {
    handleCategoryChange,
    handlePlatformsChange,
    handleTypesChange,
    handleStatusChange,
    handlePerformanceChange,
    handleSortByChange,
  } = useCustomDispatch();
  const formatName = (platform) => {
    switch (platform) {
      case "Non_Digital":
        return "Non Digital";
      case "Google_Ads":
        return "Google Ads";
      case "Google_Analytics":
        return "Google Analytics";
      case "Best_Performance":
        return "Best Performance";
      case "Worst_Performance":
        return "Worst Performance";
      case "Gaining_Velocity":
        return "Gaining Velocity";
      case "Losing_Velocity":
        return "Losing Velocity";
      default:
        return platform;
    }
  };

  return (
    <div className={"d-flex flex-wrap mt-2"}>
      {/* CategoryDropdown */}
      <div className="dropdown pe-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="categoryDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Categories
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="categoryDropdown">
          {categories.map((category, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedCategory.includes(category)}
                  onChange={() => handleCategoryChange(category)}
                />
                {`${category} (${categoryCounts[category] || 0}/${totalCounts[category] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* PlatformDropdown  */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="channelDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Platforms
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="channelDropdown">
          {platforms.map((platform, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedPlatform.includes(platform)}
                  onChange={() => handlePlatformsChange(platform)}
                  disabled={disabledPlatforms.includes(platform)}
                />
               {`${formatName(platform)} (${platformCounts[platform] || 0}/${totalCounts[platform] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* TypeDropdown */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="typeDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Types
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="typeDropdown">
          {types.map((type, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedType.includes(type)}
                  onChange={() => handleTypesChange(type)}
                />
                {`${type} (${typeCounts[type] || 0}/${totalCounts[type] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* StatusDropdown */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="statusDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Status
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="statusDropdown">
          {Status.map((status, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedStatus.includes(status)}
                  onChange={() => handleStatusChange(status)}
                />
                {`${status} (${statusCounts[status] || 0}/${totalCounts[status] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* PerformanceDropdown  */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="shadeDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Performance
        </button>
        <ul className="dropdown-menu bg-black text-white" aria-labelledby="shadeDropdown">
          {performance.map((shade, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedPerformance.includes(shade)}
                  onChange={() => handlePerformanceChange(shade)}
                />
                <span
                  className="shade-circle"
                  style={{backgroundColor: getShadeInfo(shade).color}}></span>
                {`${formatKeys(getShadeInfo(shade).label)} (${performanceCounts[getShadeInfo(shade).label] || 0}/${totalCounts[getShadeInfo(shade).label] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* SortByDropdown */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="SortByDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Sort By
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="SortByDropdown">
          {sortBy.map((sortby, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="radio"
                  className="me-2"
                  checked={sortby === selectedSortBy}
                  onChange={() => handleSortByChange(sortby)}
                />
                {formatName(sortby)}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Asset_Filters;
