import localisable from "../../../../common/constants/localisable";
import { capitalize } from "../../../../common/helpers/utils/DataHelper";
import { toast } from "react-toastify";

const formatPerformanceMetricData = (
  data,
  activeSubPlatformTab,
  activeCategoryTab,
  mediaPlatform
) => {
  const formattedData = [];

  for (const [type, metrics] of Object.entries(data)) {
    for (const [metric_field, details = {}] of Object.entries(metrics)) {
      const {
        low_score,
        average_score,
        good_score,
        excellent_score,
        is_remove_config,
      } = details;
      const scores = [low_score, average_score, good_score, excellent_score];
      const numericScores = scores.map((score) =>
        !score ? 0 : parseFloat(score)
      );

      // Check for ascending order
      const isAscending = numericScores.every(
        (score, i, arr) => i === 0 || arr[i - 1] <= score
      );

      const id = details.id;

      formattedData.push({
        ...(id && { id }),
        sub_media_platform: activeSubPlatformTab,
        use_platform_metric: details.use_platform_metric || false,
        category: activeCategoryTab,
        type,
        metric_field,
        excellent_score: parseFloat(details.excellent_score) ?? null,
        good_score: parseFloat(details.good_score) ?? null,
        average_score: parseFloat(details.average_score) ?? null,
        low_score: parseFloat(details.low_score) ?? null,
        is_increasing_score: isAscending,
        is_remove_config,
        media_platform: mediaPlatform,
      });
    }
  }

  return formattedData.length > 0 ? formattedData : null;
};

const getSubMediaPlatform = (platform) => {
  return platform.toLowerCase();
};

const parseToFloatOrEmpty = (score) => {
  const parsedValue = parseFloat(score);
  return isNaN(parsedValue) ? "" : parsedValue;
};

const validateScores = (activeTypeValues, performanceMetricFieldPath) => {
  // Iterate through each metric in activeTypeValues
  for (const [metric, obj] of Object.entries(activeTypeValues)) {
    const {
      excellent_score,
      good_score,
      average_score,
      low_score,
      use_platform_metric,
      is_increasing_score,
    } = obj;

    const scores = [
      parseToFloatOrEmpty(low_score),
      parseToFloatOrEmpty(average_score),
      parseToFloatOrEmpty(good_score),
      parseToFloatOrEmpty(excellent_score),
    ];

    // Check for missing scores
    const isMissingScore = scores.some(
      (score) => score === "" || score === null || score === undefined
    );
    if (!use_platform_metric && isMissingScore) {
      toast.error(`All score fields must be filled for ${capitalize(metric)}`);
      return false;
    }

    // Convert scores to numbers
    const numericScores = scores.map((score) =>
      !score ? 0 : parseFloat(score)
    );

    // Check for negative scores
    const hasNegativeScore = numericScores.some((score) => score < 0);
    if (hasNegativeScore) {
      toast.error(`Values for ${capitalize(metric)} cannot be negative.`);
      return false;
    }

    // Check for the correct order based on isreverse
    const isAscending = numericScores.every(
      (score, i, arr) => i === 0 || arr[i - 1] <= score
    );
    const isDescending = numericScores.every(
      (score, i, arr) => i === 0 || arr[i - 1] >= score
    );

    if (!isAscending && !isDescending) {
      toast.error(`Incorrect score order for ${capitalize(metric)}.`);
      return false;
    } else if (!is_increasing_score && !isDescending) {
      toast.error(
        `Scores for ${capitalize(metric)} must be in descending order.`
      );
      return false;
    } else if (is_increasing_score && !isAscending) {
      toast.error(
        `Scores for ${capitalize(metric)} must be in ascending order.`
      );
      return false;
    }
  }

  // All validations passed
  return true;
};

const validateGraphScores = (scores, changedScore, isreverse) => {
  let { low_score, average_score, good_score, excellent_score } = scores;
  low_score = parseFloat(low_score);
  average_score = parseFloat(average_score);
  good_score = parseFloat(good_score);
  excellent_score = parseFloat(excellent_score);

  // Only apply validation for the changed score
  switch (changedScore) {
    case "low_score":
      if (isreverse) {
        low_score = Math.max(average_score + 0.001, low_score);
        // low_score = Math.min(low_score, maxscore);
      } else {
        low_score = Math.max(0, low_score);
        low_score = Math.min(low_score, average_score - 0.001);
      }
      break;

    case "average_score":
      if (isreverse) {
        average_score = Math.max(good_score + 0.001, average_score);
        average_score = Math.min(average_score, low_score - 0.001);
      } else {
        average_score = Math.max(low_score + 0.01, average_score);
        average_score = Math.min(average_score, good_score - 0.001);
      }
      break;

    case "good_score":
      if (isreverse) {
        good_score = Math.max(excellent_score + 0.001, good_score);
        good_score = Math.min(good_score, average_score - 0.001);
      } else {
        good_score = Math.max(average_score + 0.001, good_score);
        good_score = Math.min(good_score, excellent_score - 0.001);
      }
      break;

    case "excellent_score":
      if (isreverse) {
        excellent_score = Math.max(0, excellent_score);
        excellent_score = Math.min(excellent_score, good_score - 0.001);
      } else {
        excellent_score = Math.max(good_score + 0.001, excellent_score);
        // excellent_score = Math.min(excellent_score, maxscore);
      }
      break;
    default:
      console.warn(`Unhandled score type: ${changedScore}`);
      break;
  }

  return {
    low_score: parseFloat(low_score.toFixed(4)),
    average_score: parseFloat(average_score.toFixed(4)),
    good_score: parseFloat(good_score.toFixed(4)),
    excellent_score: parseFloat(excellent_score.toFixed(4)),
  };
};

const isTwoDecimalPlaces = (value) =>
  value && !/^-?\d*(\.\d{0,2})?$/.test(value)
    ? localisable.twoDecimalPlaces
    : undefined;

/**
 * Determines if the provided asset type form data contains valid performance metric values.
 *
 * This function checks whether the given form data for an asset type (e.g., image, link, PDF)
 * includes any valid metric-related fields. It looks for specific keys (`use_platform_metric`,
 * `metric_field`, and keys that end in `_score` but are not `is_increasing_score`) and returns
 * `true` if any of these fields have a truthy value.
 *
 * @param  {Object} formTypeData - The form data object for a specific asset type, where keys
 *                                 represent form fields and values represent user input.
 * @return {boolean} - Returns `true` if the form data contains valid metric fields, otherwise `false`.
 */
const containsValidMetric = (formTypeData = {}) => {
  return Object.entries(formTypeData).some(([key, value]) => {
    return (
      value &&
      ((key === "use_platform_metric" && value) ||
        key === "metric_field" ||
        (key.includes("_score") && key !== "is_increasing_score"))
    );
  });
};

/**
 * Counts asset types with valid performance metric data in a category.
 *
 * Iterates over the form data for each asset type in a category and counts how many have valid
 * metric fields, as determined by `containsValidMetric`.
 *
 * @param  {Object} formCategoryData - The form data for various asset types within a category.
 * @return {number} - The count of asset types with valid metric fields.
 */
const getFormDataTypeCount = (formCategoryData = {}) => {
  return Object.values(formCategoryData).reduce((count, typeData) => {
    return count + (containsValidMetric(typeData) ? 1 : 0);
  }, 0);
};

const getChanges = (initialValues, activeValues) => {
  const changes = {};

  // Process keys in activeValues
  for (const key in activeValues) {
    const activeValue = activeValues[key];
    const initialValue = initialValues[key];

    // Ensure activeValue is not null or undefined before accessing properties
    if (activeValue && activeValue.is_remove_config && !initialValue) {
      continue;
    }

    if (!initialValues.hasOwnProperty(key)) {
      // Key added in activeValues
      changes[key] = activeValue;
    } else if (
      typeof activeValues[key] === "object" &&
      activeValues[key] !== null
    ) {
      // If it's an object, check for nested changes
      const nestedChanges = getChanges(initialValues[key], activeValues[key]);
      if (Object.keys(nestedChanges).length > 0) {
        // If any change is detected in the nested object, return the entire object
        changes[key] = activeValues[key];
      }
    } else {
      // Normalize both values as numbers for comparison
      const initialNumericValue = isNaN(Number(initialValues[key]))
        ? initialValues[key]
        : Number(initialValues[key]);
      const activeNumericValue = isNaN(Number(activeValues[key]))
        ? activeValues[key]
        : Number(activeValues[key]);

      if (initialNumericValue !== activeNumericValue) {
        // Value changed
        changes[key] = activeValues[key];
      }
    }
  }

  // Process keys in initialValues that are not in activeValues
  for (const key in initialValues) {
    if (!activeValues.hasOwnProperty(key)) {
      // Mark the metric as removed by setting is_remove_config to true
      changes[key] = {
        ...initialValues[key],
        is_remove_config: true,
      };
    }
  }

  return changes;
};

const getScorePair = (scoreType) => {
  switch (scoreType) {
    case "low_score":
      return ["very_low", "low"];
    case "average_score":
      return ["average", "low"];
    case "good_score":
      return ["good", "average"];
    case "excellent_score":
      return ["good", "excellent"];
    default:
      throw new Error("Invalid score type provided");
  }
};

export {
  formatPerformanceMetricData,
  getSubMediaPlatform,
  isTwoDecimalPlaces,
  validateScores,
  validateGraphScores,
  getFormDataTypeCount,
  containsValidMetric,
  getChanges,
  getScorePair,
};
