import React from "react";
import {Modal} from "react-bootstrap";

const GooglePopup = ({
  SelectedPlatform,
  ConnectAPI,
  modalOpen,
  closeModal,
  isChecked,
  setIsChecked,
  props
}) => {
  return (
    <Modal show={modalOpen} onHide={closeModal} dialogClassName="modal-scroll">
      <Modal.Header closeButton>
        <Modal.Title>Before you connect...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="border border-black rounded p-2">
            <h5 className="d-flex justify-content-center">Consent Form</h5>
            <p
              className="container"
              style={{
                maxHeight: "250px",
                overflowY: "scroll",
                textAlign: "justify",
              }}>
              Authorization for Accessing Social Media Data I,{" "}
              <b>{props.user.name}</b>, hereby authorize <b>mktg.ai</b>,
              hereinafter referred to as "the Company," to access and collect
              data from my social media accounts for the purpose of managing,
              analyzing and measuring performance of digital and non-digital
              assets. Scope of Access: The Company is granted permission to
              access my <b>Google</b> posts, advertisements, and insights. This
              access includes but is not limited to media posts, assets,
              statastics, etc. Data Usage: The data collected will be used
              solely for the purpose marketing analysis. The Company assures
              that the data collected will not be shared with any third-party
              tools or services without my explicit consent. I understand that
              my data may be used in an aggregated and anonymized form for
              statistical analysis and research purposes. Data Security: The
              Company is committed to storing all collected data securely and
              confidentially. Measures will be taken to prevent unauthorized
              access, disclosure, alteration, or destruction of the data. Access
              to my data will be restricted to authorized personnel only. By
              clicking the <b>"I Agree"</b> button, I also consent to the use of
              AI platforms that include but are not limited to Google Vertex AI,
              and Openai GPT-4 to optimize your experience, including image and
              video classification for refined search, semantic and similarity
              queries, and content search. Upholding strict privacy standards,
              we share your information solely to enhance our services, ensuring
              transparency and accountability.
            </p>
            <div className="form-check">
              <input
                className="form-check-input border border-dark"
                type="checkbox"
                checked={isChecked}
                id="consentCheckbox"
                onChange={() => setIsChecked(!isChecked)}
              />
              <label className="form-check-label" htmlFor="consentCheckbox">
                <b>
                  I Agree to the terms and conditions outlined in this consent
                  form.
                </b>
              </label>
            </div>
          </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}>
          Cancel
        </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => ConnectAPI(SelectedPlatform)}
            disabled={!isChecked}>
            Yes, I understand
          </button>
      </Modal.Footer>
    </Modal>
  );
};

export default GooglePopup;
