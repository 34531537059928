import React, { useState, useEffect, useRef, useMemo } from "react";
import "../../../Styles/style.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import HubView from "../../HomePage/Assets/HubView/Hubview";
import AssetFilters from "../elements/AssetFilters";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setStartDate,
  setEndDate,
  setHeatmapOn,
  setSizeFactor,
  setSearchText,
  resetFilters,
} from "../../../redux/actions";
import {
  categories,
  status,
  platforms,
  disabledPlatforms,
  types,
  performance,
  sortBy,
  sizes,
} from "../utils/constants";
import {
  formatDateForBackend,
  parseDateString,
  getDefaultRequestBody,
  getShadeInfo,
} from "../utils/utils";
import useCustomDispatch from "../utils/useCustomDispatch";
import ENDPOINTS from "../../../common/endpoints";
import { getAPIConfig } from "../../../common/utils";
import ProgressBarSpinner from "../../../components/ProgressSpinner";

const BrowseAsset = ({ handleSaveStep, handleCancel, addSectionRef }) => {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    selectedCategory,
    selectedPlatform,
    selectedType,
    selectedStatus,
    selectedPerformance,
    selectedSortBy,
    heatmapOn,
    sizeFactor,
    viewType,
    searchText,
  } = useSelector((state) => state.assets);
  const {
    handleCategoryChange,
    handlePlatformsChange,
    handleTypesChange,
    handleStatusChange,
    handlePerformanceChange,
    handleSortByChange,
  } = useCustomDispatch();
  const [hoveredImage, setHoveredImage] = useState(null);
  const [mediaPlatformData, setMediaPlatformData] = useState([]);
  const [renderedItemsCount, setRenderedItemsCount] = useState(
    mediaPlatformData.current_count
  );
  const [platformCounts, setPlatformCounts] = useState({});
  const [categoryCounts, setCategoryCounts] = useState({});
  const [typeCounts, setTypeCounts] = useState({});
  const [statusCounts, setStatusCounts] = useState({});
  const [performanceCounts, setPerformanceCounts] = useState({});
  const [totalCounts, setTotalCounts] = useState({});

  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const containerRef = useRef(null);
  const [fetchMoreTrigger, setFetchMoreTrigger] = useState(false);
  const [cancelTokenAssetCountSource, setCancelTokenAssetCountSource] =
    useState(null);
  const apiConfig = useMemo(() => getAPIConfig(), []);
  const [isMoreData, setIsMoreData] = useState(true);

  const fetchDataFromBackend = async (requestBody = {}) => {
    setIsLoading(true);
    setErrormsg("");

    const addToRequestBody = (key, value) => {
      if (
        value &&
        value.length > 0 &&
        value.some((item) => item.trim() !== "")
      ) {
        requestBody[key] = value.filter((item) => item.trim() !== "");
      }
    };

    if (startDate && endDate) {
      requestBody.start_date = formatDateForBackend(startDate);
      requestBody.end_date = formatDateForBackend(endDate);
    }

    addToRequestBody("category", selectedCategory);
    addToRequestBody("channel", selectedPlatform);
    addToRequestBody("type", selectedType);
    addToRequestBody("status", selectedStatus);
    if (selectedPerformance && selectedPerformance.length > 0) {
      requestBody.performance_value = selectedPerformance;
    }

    if (selectedSortBy === "Latest") {
      requestBody.sort_by = "asset_created_time";
    } else {
      requestBody.sort_by = selectedSortBy;
    }
    requestBody.semantic_search_text = searchText;

    try {
      const response = await axios.post(
        ENDPOINTS.ASSET.getAssets,
        requestBody,
        apiConfig
      );
      const data = response.data || {};
      setIsMoreData(response.data?.is_more_data);
      setMediaPlatformData(data);
      setRenderedItemsCount(data.current_count);

      // Setting filters
      const { filters = {} } = data;
      if (filters.start_date)
        handleStartDateChange(parseDateString(filters.start_date));
      if (filters.end_date)
        handleEndDateChange(parseDateString(filters.end_date));
      handleCategoryChange(filters.category);
      handlePlatformsChange(filters.channel);
      handleStatusChange(filters.status);
      handleTypesChange(filters.types);
      handlePerformanceChange(filters.performance_value);
      handleSortByChange(filters.sort_by);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("Request canceled", error.message);
      } else {
        setIsLoading(false);
        setErrormsg(
          "Try refreshing/Logging out, if still facing the same issue contact Admin"
        );
      }
    }
  };

  const getAssetCount = async (requestBody = {}, total_counts = false) => {
    if (cancelTokenAssetCountSource) {
      cancelTokenAssetCountSource.cancel(
        "Operation canceled due to new request."
      );
    }

    const source = axios.CancelToken.source();
    setCancelTokenAssetCountSource(source);
    const addToRequestBody = (key, value) => {
      if (
        value &&
        value.length > 0 &&
        value.some((item) => item.trim() !== "")
      ) {
        requestBody[key] = value.filter((item) => item.trim() !== "");
      }
    };

    if (startDate && endDate) {
      requestBody.start_date = formatDateForBackend(startDate);
      requestBody.end_date = formatDateForBackend(endDate);
    }

    addToRequestBody("category", selectedCategory);
    addToRequestBody("channel", selectedPlatform);
    addToRequestBody("type", selectedType);
    addToRequestBody("status", selectedStatus);
    if (selectedPerformance && selectedPerformance.length > 0) {
      requestBody.performance_value = selectedPerformance;
    }

    if (selectedSortBy === "Latest") {
      requestBody.sort_by = "asset_created_time";
    } else {
      requestBody.sort_by = selectedSortBy;
    }
    requestBody.semantic_search_text = searchText;
    requestBody.total_counts = total_counts;
    const config = {
      headers: apiConfig.headers,
      cancelToken: source.token,
    };

    try {
      const response = await axios.post(
        ENDPOINTS.ASSET.getCount,
        requestBody,
        config
      );
      const countData = response.data || {};

      // Setting filters
      const { filtered_counts: counts = {}, total_counts = {} } = countData;

      setCategoryCounts({
        Organic: counts.organic,
        Paid: counts.paid,
      });

      setPlatformCounts({
        Instagram: counts.instagram,
        Facebook: counts.facebook,
        Linkedin: counts.linkedin,
        Reddit: counts.reddit,
        TradeDesk: counts.tradedesk,
        Non_Digital: counts.non_digital,
        Google_Analytics: counts.google_analytics,
        Google_Ads: counts.google_ads,
        Youtube: counts.youtube,
      });

      setTypeCounts({
        Image: counts.image,
        Video: counts.video,
        Audio: counts.audio,
        PDF: counts.pdf,
        Blog: counts.blog,
        Document: counts.document,
        Webpage: counts.webpage,
        Link: counts.link,
        Text: counts.text,
        Album: counts.album,
        Event: counts.event,
      });

      setStatusCounts({
        Active: counts.active,
        Available: counts.available,
        Paused: counts.paused,
        Removed: counts.removed,
        Archived: counts.archived,
        Draft: counts.draft,
        Published: counts.published,
        Enabled: counts.enabled,
        Public: counts.public,
        Private: counts.private,
        Unlisted: counts.unlisted,
      });

      setPerformanceCounts({
        Very_Low: counts.very_low,
        Low: counts.low,
        Average: counts.average,
        Good: counts.good,
        Excellent: counts.excellent,
        Unknown: counts.unknown,
      });
      if (total_counts && Object.keys(total_counts).length > 0) {
        const totalCountsData = {
          Organic: total_counts.organic,
          Paid: total_counts.paid,
          Instagram: total_counts.instagram,
          Facebook: total_counts.facebook,
          Linkedin: total_counts.linkedin,
          Reddit: total_counts.reddit,
          TradeDesk: total_counts.tradedesk,
          Non_Digital: total_counts.non_digital,
          Google_Analytics: total_counts.google_analytics,
          Google_Ads: total_counts.google_ads,
          Youtube: total_counts.youtube,
          Image: total_counts.image,
          Video: total_counts.video,
          Audio: total_counts.audio,
          PDF: total_counts.pdf,
          Blog: total_counts.blog,
          Document: total_counts.document,
          Webpage: total_counts.webpage,
          Link: total_counts.link,
          Text: total_counts.text,
          Album: total_counts.album,
          Event: total_counts.event,
          Active: total_counts.active,
          Available: total_counts.available,
          Public: total_counts.public,
          Private: total_counts.private,
          Unlisted: total_counts.unlisted,
          Paused: total_counts.paused,
          Removed: total_counts.removed,
          Archived: total_counts.archived,
          Draft: total_counts.draft,
          Published: total_counts.published,
          Enabled: total_counts.enabled,
          Very_Low: total_counts.very_low,
          Low: total_counts.low,
          Average: total_counts.average,
          Good: total_counts.good,
          Excellent: total_counts.excellent,
          Unknown: total_counts.unknown,
        };
        setTotalCounts(totalCountsData);
        sessionStorage.setItem("total_counts", JSON.stringify(totalCountsData));
      } else {
        setTotalCounts(JSON.parse(sessionStorage.getItem("total_counts")));
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("Request canceled", error.message);
      } else {
        setIsLoading(false);
        setErrormsg(
          "Try refreshing/Logging out, if still facing the same issue contact Admin"
        );
        console.error({ error });
      }
    }
  };

  useEffect(() => {
    const defaultRequestBody = getDefaultRequestBody();
    fetchDataFromBackend(defaultRequestBody);
    getAssetCount(defaultRequestBody, true);
    // eslint-disable-next-line
  }, [resetFilters]);

  const handleImageHover = (img) => {
    setHoveredImage(img);
  };

  const handleImageHoverOut = () => {
    setHoveredImage(null);
  };

  const handleRangeChange = (event) => {
    dispatch(setSizeFactor(parseInt(sizes[event.target.value])));
  };

  const handleToggle = () => {
    dispatch(setHeatmapOn(!heatmapOn));
  };

  const handleInputChange = (event) => {
    dispatch(setSearchText(event.target.value));
  };

  const clearSearchText = () => {
    dispatch(setSearchText(""));
  };

  // Function to handle start date change
  const handleStartDateChange = (date) => {
    // Store the date in local time zone
    if (date) {
      dispatch(setStartDate(date.toISOString()));
    }
  };

  // Function to handle end date change
  const handleEndDateChange = (date) => {
    // Store the date in local time zone
    if (date) {
      dispatch(setEndDate(date.toISOString()));
    }
  };

  const handleClose = () => {
    setIsInitialFetchDone(false);
    dispatch(resetFilters());
    handleCancel();
  };

  const handleResetFilters = () => {
    setIsInitialFetchDone(false);
    dispatch(resetFilters());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (!isInitialFetchDone) {
      const defaultRequestBody = getDefaultRequestBody();
      fetchDataFromBackend(defaultRequestBody);
      getAssetCount(defaultRequestBody);
      setIsInitialFetchDone(true);
    } else {
      fetchDataFromBackend();
      getAssetCount();
    }
  };

  const update_counts = (renderedItemsCount) => {
    setRenderedItemsCount(renderedItemsCount);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      setFetchMoreTrigger((prevFetchMoreTrigger) => !prevFetchMoreTrigger);
      // console.log("scrolled")
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <div
      className="text-white border rounded-bottom mt-2"
      ref={addSectionRef}
      style={{ position: "relative", height: "500px", overflow: "hidden" }}
    >
      {" "}
      {/* Adjust height as needed */}
      <nav
        className="navbar navbar-expand-lg bg-black rounded"
        style={{ position: "absolute", top: 0, width: "100%", zIndex: 1 }}
      >
        <div className="container-fluid px-0">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="container-fluid row px-0 mx-0">
              <div className="col-12">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {/* Views */}
                  <li className="nav-item mt-1 me-2">
                    <button
                      className="btn btn-black text-white border-secondary py-1"
                      type="button"
                      id="hubViewDropdown"
                      aria-expanded="false"
                    >
                      {viewType}
                    </button>
                  </li>
                  {/* Date Picker */}
                  <li className="nav-item mt-1">
                    <div className="d-flex">
                      <div className="startdate mx-2">
                        <DatePicker
                          className="form-control bg-black text-white border-secondary p-1"
                          selected={startDate ? new Date(startDate) : null}
                          onChange={(date) => handleStartDateChange(date)}
                          placeholderText="Start Date"
                          dateFormat="MMM dd, yyyy"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          maxDate={endDate ? new Date(endDate) : new Date()}
                        />
                      </div>
                      <div className="enddate mx-2">
                        <DatePicker
                          className="form-control bg-black border-secondary text-white p-1"
                          selected={endDate ? new Date(endDate) : null}
                          onChange={(date) => handleEndDateChange(date)}
                          placeholderText="End Date"
                          dateFormat="MMM dd, yyyy"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          minDate={startDate ? new Date(startDate) : null}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </li>
                  {/* Reset/Go */}
                  <li className="nav-item mt-1">
                    <button
                      className="btn bg-secondary btn-sm rounded mx-2 px-3 text-white"
                      onClick={handleResetFilters}
                    >
                      Reset
                    </button>
                    <button
                      className="btn bg-primary btn-sm rounded mx-2 px-3 text-white"
                      onClick={handleSubmit}
                    >
                      Go
                    </button>
                  </li>
                  {/*Semantic search */}
                  <div className="input-group w-25">
                    <input
                      type="text"
                      className="form-control border-0 outline-0 custom-search-box text-white"
                      placeholder="Enter keyword"
                      value={searchText}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                    <span
                      className={`btn btn-light text-white border-0 d-flex align-items-center justify-content-center custom-search-button ${
                        searchText ? "d-block" : "d-none"
                      }`}
                      onClick={clearSearchText}
                    >
                      <i className="bi bi-x-lg"></i>
                    </span>
                    <button
                      className="btn btn-light text-white border-0 d-flex align-items-center justify-content-center custom-search-button"
                      onClick={handleSubmit}
                    >
                      <i className="bi bi-search" aria-hidden="true"></i>
                    </button>
                  </div>
                  {/* Heatmap */}
                  <li className="nav-item ms-auto mt-2">
                    <div className="d-flex align-items-center text-white rounded-2 border border-secondary ps-2">
                      <label className="me-2">Heat Map</label>
                      <div className="form-check form-switch mt-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ cursor: "pointer" }}
                          checked={heatmapOn}
                          onChange={handleToggle}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <ul className="navbar-nav me-auto align-items-center">
                  {/* Filters */}
                  <li className="nav-item">
                    <AssetFilters
                      selectedCategory={selectedCategory}
                      selectedPlatform={selectedPlatform}
                      selectedType={selectedType}
                      selectedStatus={selectedStatus}
                      selectedPerformance={selectedPerformance}
                      selectedSortBy={selectedSortBy}
                      categories={categories}
                      platforms={platforms}
                      types={types}
                      Status={status}
                      performance={performance}
                      categoryCounts={categoryCounts}
                      platformCounts={platformCounts}
                      typeCounts={typeCounts}
                      statusCounts={statusCounts}
                      performanceCounts={performanceCounts}
                      totalCounts={totalCounts}
                      getShadeInfo={getShadeInfo}
                      disabledPlatforms={disabledPlatforms}
                      sortBy={sortBy}
                    />
                  </li>
                  {/* Slider */}
                  <li className="nav-item ms-auto">
                    {viewType === "List View" ? null : (
                      <div className="d-flex align-items-center">
                        <label
                          htmlFor="customRange3"
                          className="form-label text-white mb-0 me-2"
                        >
                          Display Size
                        </label>
                        <input
                          type="range"
                          className="form-range"
                          style={{ width: "70px" }}
                          min="0"
                          max="4"
                          step="1"
                          id="customRange3"
                          value={sizes.indexOf(sizeFactor)}
                          onChange={handleRangeChange}
                        />
                      </div>
                    )}
                  </li>
                </ul>
                <div className="col-12">
                  <ul className="navbar-nav me-auto align-items-center">
                    <li className="nav-item ms-auto text-white">
                      {renderedItemsCount
                        ? `Displaying ${renderedItemsCount} of ${mediaPlatformData.total_items}`
                        : "Displaying 0 of 0 assets"}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div
        className="content w-100 position-absolute overflow-auto"
        style={{ top: "125px", bottom: "50px" }}
        ref={containerRef}
      >
        <div className="ps-2 pt-2 pb-2 pe-1">
          {isLoading && <ProgressBarSpinner isMessagesVisible={true} />}
          {!isLoading && (
            <>
              {errormsg ? (
                "" && (
                  <div className="text-white mt-4">
                    <h5>
                      Try refreshing/Logging out. If the issue persists, contact
                      Admin.
                    </h5>
                  </div>
                )
              ) : mediaPlatformData.items?.length === 0 ? (
                <div className="text-white mt-4">
                  <h5>No asset found</h5>
                </div>
              ) : (
                mediaPlatformData.items &&
                mediaPlatformData.items.length !== 0 && (
                  <>
                    <HubView
                      sizeFactor={sizeFactor}
                      heatmapOn={heatmapOn}
                      data={mediaPlatformData}
                      handleImageHover={handleImageHover}
                      handleImageHoverOut={handleImageHoverOut}
                      viewType={viewType}
                      hoveredImage={hoveredImage}
                      startDate={startDate}
                      endDate={endDate}
                      selectedCategory={selectedCategory}
                      selectedPlatform={selectedPlatform}
                      selectedType={selectedType}
                      selectedStatus={selectedStatus}
                      selectedPerformance={selectedPerformance}
                      selectedSortBy={selectedSortBy}
                      update_counts={update_counts}
                      semanticSearchText={searchText}
                      handleSaveStep={handleSaveStep}
                      isBrowseAsset={true}
                      fetchMoreTrigger={fetchMoreTrigger}
                      isMoreData={isMoreData}
                      setIsMoreData={setIsMoreData}
                    />
                  </>
                )
              )}
            </>
          )}
        </div>
      </div>
      <div
        className="cancel-button w-100 my-2"
        style={{ position: "absolute", bottom: 0 }}
      >
        <button className="btn btn-secondary ms-2" onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default BrowseAsset;
