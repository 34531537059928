import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../../../components/spinner";
import { highlightText } from "../../utils/MetaPopup_Helper";

const AdaccountsSection = ({
  data,
  formData,
  setFormData,
  toggleSelection,
  toggleSelectAll,
}) => {
  const [standaloneAdAccountsVisible, setStandaloneAdAccountsVisible] =
    useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const loaderRef = useRef(null);
  const observer = useRef(null);

  // Combine visible and non-visible ad accounts for search
  const allAdAccounts = [
    ...data.standalone_ad_accounts,
    ...(data.all_ad_accounts || []),
  ];

  // Filter ad accounts based on the search term
  const filteredAdAccounts = allAdAccounts.filter(
    (account) =>
      account.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      account.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Limit visible accounts based on the standaloneAdAccountsVisible state
  const visibleAccounts = filteredAdAccounts.slice(
    0,
    standaloneAdAccountsVisible
  );

  useEffect(() => {
    const loadMoreAccounts = (entries) => {
      if (entries[0].isIntersecting) {
        // Add a delay before loading more accounts
        setTimeout(() => {
          setStandaloneAdAccountsVisible((prev) => prev + 5);
        }, 1000);
      }
    };

    observer.current = new IntersectionObserver(loadMoreAccounts, {
      rootMargin: "100px",
    });

    if (loaderRef.current) {
      observer.current.observe(loaderRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div className="standalone-ad-accounts">
      {visibleAccounts.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          No Pages to display
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <input
              type="text"
              className="form-control w-75"
              placeholder="Search by name or ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              className="btn btn-link text-decoration-none"
              onClick={() =>
                toggleSelectAll(
                  "selectedStandaloneAdAccounts",
                  data.standalone_ad_accounts,
                  setFormData
                )
              }
            >
              {formData.selectedStandaloneAdAccounts.length ===
              data.standalone_ad_accounts.length
                ? "Deselect All"
                : "Select All"}
            </button>
          </div>
          {visibleAccounts.map((account) => (
            <div
              key={account.id}
              className="d-flex justify-content-between align-items-center form-check py-2"
            >
              <label
                htmlFor={`standaloneAdAccount-${account.id}`}
                className="form-check-label"
              >
                <div>
                  <span>{highlightText(account.name, searchTerm)}</span>
                  <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>
                    {highlightText(account.id, searchTerm)}
                  </div>
                </div>
              </label>
              <div className="form-check form-switch ms-auto me-3">
                <input
                  type="checkbox"
                  id={`standaloneAdAccount-${account.id}`}
                  className="form-check-input"
                  onChange={() =>
                    toggleSelection(
                      "selectedStandaloneAdAccounts",
                      account.id,
                      setFormData
                    )
                  }
                  checked={formData.selectedStandaloneAdAccounts.includes(
                    account.id
                  )}
                  style={{ transform: "scale(1.5)", cursor: "pointer" }}
                />
              </div>
            </div>
          ))}
          {filteredAdAccounts.length > standaloneAdAccountsVisible && (
            <div ref={loaderRef}>
              <Spinner />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdaccountsSection;
