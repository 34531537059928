import React, {useState, useEffect} from "react";
import Spinner from "../../../components/spinner";
import axios from "axios";
import Clients from "./Clients";
import Users from "./Users";
import config from "../../../config.json";
import { getUser } from "../../../common/utils";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/actions";

const AdminConsole = (props) => {
  const [currentView, setCurrentView] = useState(
    props.user.user_role === "super_admin" ? "Clients" : "Users"
  );
  const [clientData, setClientData] = useState([]);
  const [newClient, setNewClient] = useState({
    name: "",
    is_active: false,
    logo: null,
  });
  const [clientToEdit, setClientToEdit] = useState({
    id: null,
    update_logo: null,
    name: "",
    is_active: true,
  });
  const [userData, setUserData] = useState([]);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    client: "",
  });
  const [userToEdit, setUserToEdit] = useState({
    id: null,
    name: "",
    role: "",
    is_active: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });
  const [Message, setMessage] = useState("");
  const [unauthorizedError, setUnauthorizedError] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const dispatch = useDispatch();

  const fetchUser = async () => {
    try {
      const response = await getUser();
      if (response.status === 200) {
        dispatch(setUser(response.data.user));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClientData = () => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(`${config.serviceUrl}/onboarding/list-client`, configuration)
      .then((response) => {
        setClientData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching client details:", error);
        if (error.response && error.response.status === 401) {
          setUnauthorizedError(true); // Set state to indicate unauthorized error
        }
        setIsLoading(false);
      });
  };

  const fetchUserData = () => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      is_admin_console: true,
    };
    axios
      .post(`${config.serviceUrl}/onboarding/list-users`, body, configuration)
      .then((response) => {
        setUserData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching user details:", error);
        if (error.response && error.response.status === 401) {
          setUnauthorizedError(true); // Set state to indicate unauthorized error
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchClientData();
    fetchUserData();
  }, []);

  const handleChange = (e, form) => {
    const {name, value, type, checked} = e.target;
    const newValue = type === "checkbox" ? checked : value;

    if (form === "client") {
      if (name === "name") {
        setShowErrorMessage(false);
      }
      setNewClient({...newClient, [name]: newValue});
    } else if (form === "user") {
      if (name === "email" && value.trim() !== "") {
        // Validate email format
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        setError((prevError) => ({
          ...prevError,
          email: isValidEmail ? "" : "Invalid Email",
        }));
        setNewUser({...newUser, [name]: newValue});
      } else {
        setNewUser({...newUser, [name]: newValue});
      }
    } else if (form === "edit_user") {
      setUserToEdit({...userToEdit, [name]: newValue});
    } else if (form === "edit_client") {
      setClientToEdit({...clientToEdit, [name]: newValue});
    }
  };

  const handleFormChange = (event) => {
    setFormValid(event.target.form.checkValidity());
  };

  const clearFormData = (action) => {
    setFormValid(false);
    if (action === "addClient") {
      setNewClient({
        name: "",
        is_active: false,
        logo: null,
      });
    } else if (action === "editClient") {
      setClientToEdit({name: "", isActive: false, update_logo: null});
    } else if (action === "addUser") {
      setNewUser({
        name: "",
        email: "",
        password: "",
        role: "",
        client: "",
      });
    } else if (action === "editUser") {
      setUserToEdit({ id: null, name: "", role: "", is_active: true,});
    }
  };

  return (
    <div className="d-flex flex-column">
      <div
        className={
          "m-0 p-0 inner-nav position-fixed fixed-top row justify-content-end" +
          (props.isNavbarVisible ? " nav-up" : " nav-down")
        }
        style={{zIndex: "10"}}>
        {props.isSideBar && <div className="col-3 col-md-3 col-lg-2"></div>}
        <div
          className={`${
            props.isSideBar ? "col-9 col-md-9 col-lg-10" : "col"
          } transition-width p-0`}>
          {/* Navigation bar */}
          <nav className="navbar navbar-expand-lg navbar-dark bg-black border border-secondary">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav col-4">
                  {props.user.user_role === "super_admin" && (
                    <li className="nav-item me-1">
                      <button
                        className={`btn nav-link ${
                          currentView === "Clients"
                            ? "active"
                            : "text-secondary"
                        }`}
                        onClick={() => setCurrentView("Clients")}>
                        Clients
                      </button>
                    </li>
                  )}
                  <li className="nav-item">
                    <button
                      className={`btn nav-link ${
                        currentView === "Users" ? "active" : "text-secondary"
                      }`}
                      onClick={() => setCurrentView("Users")}>
                      Users
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="position-relative text-white pb-4" style={{top: "125px"}}>
        {unauthorizedError ? (
          <div className="d-flex justify-content-center align-items-center">
            <h5>Contact Admin to access this page.</h5>
          </div>
        ) : (
          <>
            {/* Render different views based on currentView */}
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            ) : (
              <>
                {currentView === "Clients" && (
                  <Clients
                    fetchClientData={fetchClientData}
                    clientData={clientData}
                    newClient={newClient}
                    setNewClient={setNewClient}
                    clientToEdit={clientToEdit}
                    setClientToEdit={setClientToEdit}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setShowErrorMessage={setShowErrorMessage}
                    showErrorMessage={showErrorMessage}
                    setMessage={setMessage}
                    Message={Message}
                    setFormValid={setFormValid}
                    formValid={formValid}
                    clearFormData={clearFormData}
                    handleChange={handleChange}
                    handleFormChange={handleFormChange}
                    fetchUser={fetchUser}
                  />
                )}
                {currentView === "Users" && (
                  <Users
                    logged_user={props.user}
                    fetchClientData={fetchClientData}
                    fetchUserData={fetchUserData}
                    userData={userData}
                    clientData={clientData}
                    setUserToEdit={setUserToEdit}
                    userToEdit={userToEdit}
                    clientToEdit={clientToEdit}
                    setNewUser={setNewUser}
                    newUser={newUser}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    error={error}
                    setError={setError}
                    setFormValid={setFormValid}
                    formValid={formValid}
                    clearFormData={clearFormData}
                    handleChange={handleChange}
                    handleFormChange={handleFormChange}
                    fetchUser={fetchUser}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminConsole;
