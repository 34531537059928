// Handle toggling of selection for ad accounts, pages, and Instagram accounts
const toggleSelection = (category, id, setFormData) => {
  setFormData((prevFormData) => {
    const updatedCategory = prevFormData[category].includes(id)
      ? prevFormData[category].filter((item) => item !== id)
      : [...prevFormData[category], id];
    return { ...prevFormData, [category]: updatedCategory };
  });
};

// Handle toggling for all ad accounts, pages
const toggleSelectAll = (category, items, setFormData, instagramKey = null) => {
  setFormData((prevFormData) => {
    // Determine if we're selecting or deselecting
    const selectedItems =
      prevFormData[category].length === items.length
        ? [] // Deselect all
        : items.map((item) => item.id); // Select all

    // Collect Instagram account IDs if provided, based on selection or deselection
    let updatedInstagramAccounts = [...prevFormData.selectedInstagramAccounts];

    if (instagramKey) {
      items.forEach((item) => {
        const instagramId = item[instagramKey]?.id;

        // If selecting all, add Instagram ID to selectedInstagramAccounts
        if (
          selectedItems.length === items.length &&
          instagramId &&
          !updatedInstagramAccounts.includes(instagramId)
        ) {
          updatedInstagramAccounts.push(instagramId);
        }

        // If deselecting all, remove Instagram ID from selectedInstagramAccounts
        if (selectedItems.length === 0 && instagramId) {
          updatedInstagramAccounts = updatedInstagramAccounts.filter(
            (id) => id !== instagramId
          );
        }
      });
    }

    return {
      ...prevFormData,
      [category]: selectedItems,
      selectedInstagramAccounts: updatedInstagramAccounts,
    };
  });
};

// Handle toggling for instagram accounts
const toggleInstagramSelection = (accountId, formData, setFormData) => {
  setFormData({
    ...formData,
    selectedInstagramAccounts: formData.selectedInstagramAccounts.includes(
      accountId
    )
      ? formData.selectedInstagramAccounts.filter((id) => id !== accountId)
      : [...formData.selectedInstagramAccounts, accountId],
  });
};

// Function to format the data for submission
const formatSelectionForSubmit = (formData, data) => {
  const result = {
    meta_adaccount: [],
    facebook_page: [],
    instagram_account: [],
  };

  const isSelected = (id, selectedIds) => selectedIds.includes(id);

  // Process all meta_adaccounts (both selected and not selected)
  data.businesses.forEach((business) => {
    business.ad_accounts.forEach((adAccount) => {
      result.meta_adaccount.push({
        id: adAccount.id,
        name: adAccount.name,
        type: "adaccounts",
        business_id: business.id,
        business_name: business.name,
        is_active: isSelected(adAccount.id, formData.selectedAdAccounts),
      });
    });
  });

  data.standalone_ad_accounts.forEach((adAccount) => {
    result.meta_adaccount.push({
      id: adAccount.id,
      name: adAccount.name,
      type: "adaccounts",
      is_active: isSelected(adAccount.id, formData.selectedStandaloneAdAccounts),
    });
  });

  // Process all facebook_pages (both selected and not selected)
  data.businesses.forEach((business) => {
    const addedPages = new Set(); // Track added page IDs

    if (business.primary_page) {
      result.facebook_page.push({
        id: business.primary_page.id,
        name: business.primary_page.name,
        type: "pages",
        business_id: business.id,
        business_name: business.name,
        is_primary: true,
        is_active: isSelected(
          business.primary_page.id,
          formData.selectedPrimaryPage
        ),
      });
      addedPages.add(business.primary_page.id); // Mark primary_page as added
    }

    business.pages.forEach((page) => {
      if (!addedPages.has(page.id)) {
        // Avoid adding duplicate pages
        result.facebook_page.push({
          id: page.id,
          name: page.name,
          type: "pages",
          business_id: business.id,
          business_name: business.name,
          is_primary: false,
          is_active: isSelected(page.id, formData.selectedPages),
        });
        addedPages.add(page.id); // Mark page as added
      }
    });
  });

  data.standalone_pages.forEach((page) => {
    // Add page data to facebook_page
    result.facebook_page.push({
      id: page.id,
      name: page.name,
      type: "pages",
      is_primary: false,
      is_active: isSelected(page.id, formData.selectedStandalonePages),
    });
  
    // Check and add connected_instagram_account data to instagram_account
    if (page.connected_instagram_account) {
      const instagramAccount = page.connected_instagram_account;
      result.instagram_account.push({
        id: instagramAccount.id,
        name: instagramAccount.username,
        type: "instagram_account",
        page_id: page.id,
        page_name: page.name,
        is_active: isSelected(instagramAccount.id, formData.selectedInstagramAccounts),
      });
    }
  });  

  // Process all instagram_accounts (both selected and not selected)
  data.businesses.forEach((business) => {
    business.pages.forEach((page) => {
      if (page.connected_instagram_account) {
        const instagramAccount = page.connected_instagram_account;
        result.instagram_account.push({
          id: instagramAccount.id,
          name: instagramAccount.username,
          type: "instagram_account",
          business_id: business.id,
          business_name: business.name,
          page_id: page.id,
          page_name: page.name,
          is_active: isSelected(
            instagramAccount.id,
            formData.selectedInstagramAccounts
          ),
        });
      }
    });
  });

  return result;
};

// Function to format the data for update
const formatSelectionForUpdate = (formData, initialFormData, data) => {
  const getChanges = (current, initial) => {
    const added = current.filter(id => !initial.includes(id));
    const removed = initial.filter(id => !current.includes(id));
    return { added, removed };
  };

  const findFkId = (id, data) => {
    // Recursively traverse the nested structure to find the matching id and extract fk_id
    const traverse = (obj) => {
      if (Array.isArray(obj)) {
        for (const item of obj) {
          const result = traverse(item);
          if (result) return result;
        }
      } else if (typeof obj === 'object' && obj !== null) {
        if (obj.id === id) return obj.fk_id;
        for (const key in obj) {
          const result = traverse(obj[key]);
          if (result) return result;
        }
      }
      return null;
    };
    return traverse(data);
  };

  const combinedChanges = { added: [], removed: [] };

  // Compare arrays in formData and initialFormData
  Object.keys(formData).forEach(key => {
    if (Array.isArray(formData[key]) && Array.isArray(initialFormData[key])) {
      const { added, removed } = getChanges(formData[key], initialFormData[key]);
      combinedChanges.added = [...new Set([...combinedChanges.added, ...added])];
      combinedChanges.removed = [...new Set([...combinedChanges.removed, ...removed])];
    }
  });

  // Map added and removed to desired output format
  const result = {
    data: [
      ...combinedChanges.added.map(id => ({
        id: findFkId(id, data),
        is_active: true,
      })),
      ...combinedChanges.removed.map(id => ({
        id: findFkId(id, data),
        is_active: false,
      }))
    ]
  };

  return result;
};

// Initialize formData based on is_active status
const initializeFormData = (data) => {
  const updatedFormData = {
    selectedBusinesses: [],
    selectedAdAccounts: [],
    selectedPages: [],
    selectedPrimaryPage: [],
    selectedInstagramAccounts: [],
    selectedStandaloneAdAccounts: [],
    selectedStandalonePages: [],
  };

  // Process Businesses
  if (data.businesses) {
    data.businesses.forEach((business) => {
      // Process AdAccounts under Businesses
      if (business.ad_accounts) {
        business.ad_accounts.forEach((adAccount) => {
          if (adAccount.is_active === true) {
            updatedFormData.selectedAdAccounts.push(adAccount.id);
          }
        });
      }

      // Process Primary Pages
      if (business.primary_page && typeof business.primary_page === "object") {
        if (business.primary_page.is_active === true) {
          updatedFormData.selectedPrimaryPage.push(business.primary_page.id);
        }
      
        if (
          business.primary_page.connected_instagram_account &&
          business.primary_page.connected_instagram_account.is_active === true
        ) {
          updatedFormData.selectedInstagramAccounts.push(
            business.primary_page.connected_instagram_account.id
          );
        }
      }

      // Process Pages
      if (business.pages) {
        business.pages.forEach((page) => {
          if (page.is_active === true) {
            updatedFormData.selectedPages.push(page.id);

            // Process Instagram Connected Account
            if (
              page.connected_instagram_account &&
              page.connected_instagram_account.is_active === true
            ) {
              updatedFormData.selectedInstagramAccounts.push(
                page.connected_instagram_account.id
              );
            }
          }
        });
      }
    });
  }

  // Process Standalone AdAccounts
  if (data.standalone_ad_accounts) {
    data.standalone_ad_accounts.forEach((adAccount) => {
      if (adAccount.is_active === true) {
        updatedFormData.selectedStandaloneAdAccounts.push(adAccount.id);
      }
    });
  }

  // Process Standalone Pages
  if (data.standalone_pages) {
    data.standalone_pages.forEach((page) => {
      if (page.is_active === true) {
        updatedFormData.selectedStandalonePages.push(page.id);
      }
      // Process Instagram Connected Account for Standalone Pages
      if (
        page.connected_instagram_account &&
        page.connected_instagram_account.is_active === true
      ) {
        updatedFormData.selectedInstagramAccounts.push(
          page.connected_instagram_account.id
        );
      }
    });
  }

  return updatedFormData;
};

// Function to highlight searched text
const highlightText = (text, searchQuery) => {
  if (!searchQuery) return text;
  const regex = new RegExp(`(${searchQuery})`, "gi");
  return text.split(regex).map((part, index) =>
    part.toLowerCase() === searchQuery.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: "yellow" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

export {
  toggleSelection,
  toggleSelectAll,
  toggleInstagramSelection,
  formatSelectionForSubmit,
  formatSelectionForUpdate,
  initializeFormData,
  highlightText,
};
