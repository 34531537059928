import React, { useState, useEffect } from "react";
import { MESSAGES } from '../common/constants/constants';
import "../Styles/style.css";

function ProgressBarSpinner({ isMessagesVisible = false }) {
  // eslint-disable-next-line
  const [currentMessage, setCurrentMessage] = useState(MESSAGES[0]);

  useEffect(() => {
    if (isMessagesVisible) {
      const messageInterval = setInterval(() => {
        setCurrentMessage((prevMessage) => {
          const currentIndex = MESSAGES.indexOf(prevMessage);
          const nextIndex = (currentIndex + 1) % MESSAGES.length; // Loop back to the first message
          return MESSAGES[nextIndex];
        });
      }, 1000);

      return () => clearInterval(messageInterval);
    }
  }, [isMessagesVisible]);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {isMessagesVisible && (
        <div className="loading-text">
          {currentMessage}
          <span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </div>
      )}
    </div>
  );
}

export default ProgressBarSpinner;
