import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setId } from "../../../../redux/actions";
import axios from "axios";
import config from "../../../../config.json";
import ProgressBarSpinner from "../../../../components/ProgressSpinner";
import whiteLabel from "../../../../assets/whitelabelConfig";
import "../../../../Styles/style.css";
import Cardview from "./Cardview";
import { formatDateForBackend, getShadeInfo } from "../../utils/utils";
import { dateFormatter } from "../../../../common/helpers/utils/DateTime";

const columnWidths = {
  2: "col-6",
  3: "col-4",
  4: "col-3",
  6: "col-2",
  12: "col-1",
};

const mapSubMediaPlatform = (platform) => {
  const platformMap = {
    video_television: "video/television",
    audio_radio: "audio/radio",
    out_of_home_billboards: "out of home/billboards",
    google_analytics: "google analytics",
  };
  return platformMap[platform] || platform;
};

const calculateFontSize = (sizeFactor) => {
  const columnSizes = Object.keys(columnWidths);
  const maxColumnSize = Math.max(...columnSizes);
  const minColumnSize = Math.min(...columnSizes);
  const fontSizeRange = [6, 16];
  const scaleFactor =
    (maxColumnSize - sizeFactor) / (maxColumnSize - minColumnSize);
  return `${
    fontSizeRange[0] + scaleFactor * (fontSizeRange[1] - fontSizeRange[0])
  }px`;
};

const HubView = (props) => {
  const {
    sizeFactor,
    heatmapOn,
    data,
    startDate,
    endDate,
    selectedCategory,
    selectedPlatform,
    selectedType,
    selectedStatus,
    selectedPerformance,
    selectedSortBy,
    update_counts,
    semanticSearchText,
    isMoreData,
    setIsMoreData,
    isBrowseAsset,
    handleSaveStep,
    fetchMoreTrigger,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [asset, setasset] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [items, setItems] = useState(data.items);
  const [currentPage, setCurrentPage] = useState(data.current_page);
  const [renderedItemsCount, setRenderedItemsCount] = useState(
    data.current_count
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false); // New state to track fetch status
  const { total_pages } = data;

  const columnHeights = useRef(new Array(sizeFactor).fill(0));
  const itemRefs = useRef([]); // Ref to store item heights
  const scrollTimer = useRef(null);

  const fetchMoreData = useCallback(() => {
    if (isMoreData && !isFetching) {
      setIsFetching(true);
      setIsLoading(true);
      const authToken = localStorage.getItem("token");
      const configHeaders = {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      };

      let requestBody = {
        page: currentPage + 1,
        start_date: startDate ? formatDateForBackend(startDate) : undefined,
        end_date: endDate ? formatDateForBackend(endDate) : undefined,
        category: selectedCategory?.filter((item) => item.trim() !== ""),
        channel: selectedPlatform?.filter((item) => item.trim() !== ""),
        type: selectedType?.filter((item) => item.trim() !== ""),
        status: selectedStatus?.filter((item) => item.trim() !== ""),
        performance_value: selectedPerformance,
        sort_by: selectedSortBy,
        semantic_search_text: semanticSearchText,
      };

      axios
        .post(
          `${config.serviceUrl}/assets/get-assets`,
          requestBody,
          configHeaders
        )
        .then((response) => {
          const newItems = response.data.items;
          setItems((prevItems) => [...prevItems, ...newItems]);
          setRenderedItemsCount(response.data.current_count);
          update_counts(response.data.current_count);
          setCurrentPage((prevPage) => prevPage + 1);
          setIsMoreData(response.data?.is_more_data);
        })
        .catch((error) => {
          console.error("Error fetching media platform data:", error);
        })
        .finally(() => {
          setIsLoading(false);
          setIsFetching(false);
        });
    }
    // eslint-disable-next-line
  }, [
    currentPage,
    total_pages,
    startDate,
    endDate,
    selectedCategory,
    selectedPlatform,
    selectedType,
    selectedStatus,
    selectedPerformance,
    selectedSortBy,
    semanticSearchText,
    update_counts,
    isFetching,
    isMoreData,
  ]);

  useEffect(() => {
    if (isBrowseAsset) {
      fetchMoreData();
    }
    // eslint-disable-next-line
  }, [fetchMoreTrigger]);

  const handleMouseEnter = (e) => {
    const overlay = e.currentTarget.querySelector(".overlay");
    const videoLogo = e.currentTarget.querySelector(".video-logo");
    const imageLogo = e.currentTarget.querySelector(".image-logo");
    const socialLogo = e.currentTarget.querySelector(".social-logo");

    if (overlay) overlay.style.opacity = 1;
    if (videoLogo) videoLogo.style.display = "none";
    if (imageLogo) imageLogo.style.display = "none";
    if (socialLogo) socialLogo.style.display = "none";
  };

  const handleMouseLeave = (e) => {
    const overlay = e.currentTarget.querySelector(".overlay");
    const videoLogo = e.currentTarget.querySelector(".video-logo");
    const imageLogo = e.currentTarget.querySelector(".image-logo");
    const socialLogo = e.currentTarget.querySelector(".social-logo");

    if (overlay) overlay.style.opacity = 0;
    if (videoLogo) videoLogo.style.display = "block";
    if (imageLogo) imageLogo.style.display = "block";
    if (socialLogo) socialLogo.style.display = "block";
  };

  const closeCardModal = () => setModalOpen(false);

  const openCardModal = (advertisement, event) => {
    event.stopPropagation();
    setasset(advertisement);
    setModalOpen(true);
  };

  const handleImageClick = (image) => {
    setModalOpen(false);
    if (isBrowseAsset) {
      handleSaveStep(image);
    } else {
    const encodedImageId = encodeURIComponent(image.id);
    dispatch(setId(image.id));
    const formattedStartDate = dateFormatter(startDate);
    const formattedEndDate = dateFormatter(endDate);
    setTimeout(
      () =>
        navigate(
          `/assets/details/${encodedImageId}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        ),
      500
    );
    }
  };

  const myInfiniteScroll = useCallback(() => {
    const d = document.getElementById("myscroll");
    if (scrollTimer.current !== null) clearTimeout(scrollTimer.current);

    scrollTimer.current = setTimeout(() => {
      const h = ((d.scrollTop + d.offsetHeight) / d.scrollHeight) * 100;
      if (h > 90) fetchMoreData();
    }, 150);
  }, [fetchMoreData]);

  useEffect(() => {
    const myScrollElement = document.getElementById("myscroll");
    if (myScrollElement) {
      myScrollElement.addEventListener("scroll", myInfiniteScroll);
      return () =>
        myScrollElement.removeEventListener("scroll", myInfiniteScroll);
    }
  }, [myInfiniteScroll]);

  const fetchMoreDataIfNeeded = useCallback(() => {
    const maxRenderableItems = Math.ceil(window.innerHeight / 60) * sizeFactor;
    if (isMoreData && renderedItemsCount < maxRenderableItems) {
      fetchMoreData();
    }
  }, [renderedItemsCount, sizeFactor, fetchMoreData, isMoreData]);

  useEffect(() => {
    fetchMoreDataIfNeeded();
  }, [sizeFactor, renderedItemsCount, fetchMoreDataIfNeeded, isMoreData]);

  useEffect(() => {
    // Update column heights based on the actual rendered heights of the items
    itemRefs.current.forEach((ref, index) => {
      if (ref && ref.current) {
        const columnIndex = index % sizeFactor;
        columnHeights.current[columnIndex] += ref.current.clientHeight;
      }
    });
  }, [items, renderedItemsCount, sizeFactor]);

  const columns = useMemo(() => {
    const cols = new Array(sizeFactor).fill(null).map(() => []);
    items.slice(0, renderedItemsCount).forEach((item, index) => {
      const columnIndex = index % sizeFactor;
      cols[columnIndex].push(item);
    });
    return cols;
  }, [items, renderedItemsCount, sizeFactor]);

  return (
    <div className="row m-0 mb-3">
      {columns.map((column, columnIndex) => (
        <div
          key={`column-${columnIndex}`}
          className={`hubview-column pe-1 pb-1 ps-0 pt-0 ${columnWidths[sizeFactor]}`}
        >
          {column.map((item, index) => (
            <div
              key={`item-${item.id}-${index}`}
              data-key={item.id}
              className="hubview-item position-relative overflow-hidden mb-1 text-black"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleImageClick(item)}
              role="button"
            >
              {heatmapOn && (
                <div
                  className="heatmap-overlay position-absolute top-0 start-0 w-100 h-100"
                  style={{
                    backgroundColor: getShadeInfo(item.performance_value, true)
                      .color,
                    opacity: "0.8",
                  }}
                ></div>
              )}

              {item.type === "text" ? (
                <div
                  className="text-ad"
                  title={item.title}
                  style={{
                    fontSize: calculateFontSize(sizeFactor),
                    height: "100px",
                  }}
                >
                  {item.title?.length > 50
                    ? `${item.title.substring(0, 50)}...`
                    : item.title}
                </div>
              ) : (
                <img
                  src={
                    item.type === "audio"
                      ? item.gcs_thumbnail_url || whiteLabel.audio_thumbnail
                      : item.gcs_thumbnail_url || whiteLabel.alt_image
                  }
                  onError={(e) => {
                    e.target.src = whiteLabel.alt_image;
                  }}
                  alt={`Item ${index}`}
                  className="img-fluid w-100"
                />
              )}

              <div className="position-absolute top-0 start-0 mx-2">
                <i
                  className={`bi ${
                    {
                      video: "bi-camera-video-fill",
                      image: "bi-card-image",
                      pdf: "bi-filetype-pdf",
                      audio: "bi-volume-up-fill",
                      document: "bi-file-earmark-text",
                      webpage: "bi-filetype-html",
                      link: "bi-link-45deg",
                    }[item.type] || "bi-file-text-fill"
                  }`}
                  style={{ fontSize: calculateFontSize(sizeFactor) }}
                ></i>
              </div>

              <div className="social-logo position-absolute top-0 end-0">
                {item.media_platform === "Non_Digital" ? (
                  <i
                    className="bi bi-folder-plus me-2"
                    style={{ fontSize: calculateFontSize(sizeFactor) }}
                  ></i>
                ) : item.sub_media_platform === "tradedesk" ? (
                  <img
                    src={whiteLabel.TradeDesk_logo}
                    alt={item.sub_media_platform}
                    className="me-2"
                    style={{
                      width: calculateFontSize(sizeFactor),
                      height: calculateFontSize(sizeFactor),
                    }}
                  />
                ) : (
                  <i
                    className={`bi bi-${
                      ["google_ads", "google_analytics"].includes(
                        item.sub_media_platform
                      )
                        ? "google"
                        : item.sub_media_platform
                    } me-2`}
                    style={{ fontSize: calculateFontSize(sizeFactor) }}
                  ></i>
                )}
              </div>

              <div className="overlay position-absolute opaque-25 d-flex flex-column top-0 start-0 w-100 h-100 text-white">
                <div className="row-icons d-flex w-100">
                  <div className="left-icon mx-2">
                    <i
                      className={`bi ${
                        {
                          video: "bi-camera-video-fill",
                          image: "bi-card-image",
                          pdf: "bi-filetype-pdf",
                          audio: "bi-volume-up-fill",
                          document: "bi-file-earmark-text",
                          webpage: "bi-filetype-html",
                          link: "bi-link-45deg",
                        }[item.type] || "bi-file-text-fill"
                      }`}
                      style={{ fontSize: calculateFontSize(sizeFactor) }}
                    ></i>
                  </div>
                  <div className="right-icon ms-auto mx-2">
                    <button
                      className="bg-transparent border-0 p-0"
                      onClick={(event) => openCardModal(item, event)}
                    >
                      <i
                        className="bi bi-card-text text-white"
                        style={{ fontSize: calculateFontSize(sizeFactor) }}
                      ></i>
                    </button>
                  </div>
                </div>
                <div
                  className="row-text mx-3"
                  style={{
                    fontSize: calculateFontSize(sizeFactor),
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <p>{item.type === "audio" ? item.asset_name : item.title}</p>
                </div>
                {item.category !== "Paid" &&
                  item.sub_media_platform !== "google_analytics" && (
                    <div
                      className="row-icons-bottom d-flex justify-content-center align-items-center mt-auto"
                      style={{ fontSize: calculateFontSize(sizeFactor) }}
                    >
                      <span className="mx-1">
                        <i className="bi bi-heart-fill me-2"></i>
                        {item.insights?.likes}
                      </span>
                      <span className="mx-1">
                        <i className="bi bi-chat-fill me-2"></i>
                        {item.insights?.comments}
                      </span>
                      <span className="mx-1">
                        <i className="bi bi-share-fill me-2"></i>
                        {item.insights?.shares}
                      </span>
                    </div>
                  )}
                <div
                  className="row-mediaplatform-bottom d-flex justify-content-center align-items-center pb-1"
                  style={{ fontSize: calculateFontSize(sizeFactor) }}
                >
                  {mapSubMediaPlatform(item.sub_media_platform)}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
      {isLoading && <ProgressBarSpinner />}
      {asset && (
        <Modal show={modalOpen} onHide={closeCardModal} size="sm">
          <Modal.Header
            className="card-modal border-0 pb-0"
            closeButton
          ></Modal.Header>
          <Modal.Body className="card-modal rounded-bottom">
            <Cardview
              modalOpen={modalOpen}
              closeCardModal={closeCardModal}
              asset={asset}
              handleImageClick={handleImageClick}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default HubView;
