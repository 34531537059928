import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import PerformanceMetric from "./PerformanceSettings/PerformanceMetric";
import MetaPopup from "./Meta/MetaPopup";
import useFetchMetaAccounts from "./utils/useFetchMetaAccounts";
import TradeDeskPopup from "./TradeDesk/TradeDeskPopup";
import GooglePopup from "./Google/GooglePopup";
import whiteLabel from "../../../assets/whitelabelConfig";
import Spinner from "../../../components/spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/style.css";
import { getAPIConfig } from "../../../common/utils";
import ENDPOINTS from "../../../common/endpoints";
import { toast } from "react-toastify";
import { capitalize } from "../../../common/helpers/utils/DataHelper";
import { labelFormatter } from "../../../common/helpers/utils/Formatter";

function Dashboard_API(props) {
  const {fetchAccounts, accountsData, isLoading } = useFetchMetaAccounts();
  const [SelectedPlatform, setSelectedPlatform] = useState(null);
  const [performanceMatrixOpen, setPerformanceMatrixOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showMetaPopup, setShowMetaPopup] = useState(false);
  const [formData, setFormData] = useState({
    media_platform: "",
    token: "",
    description: "",
    performance_report_name: "",
    start_date: "",
    end_date: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const apiHeaderConfig = useMemo(() => getAPIConfig(), []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successmessage = searchParams.get("message");
    const errormessage = searchParams.get("error_message");
    const media_platform = searchParams.get("media_platform");
    if (successmessage && media_platform === "Meta") {
      setShowMetaPopup(true); // Keep this if needed for the Meta popup
      navigate({ search: "" });
    } else if (successmessage && media_platform !== "Meta") {
      if (successmessage.includes("Failed")) {
        toast.error(capitalize(labelFormatter(successmessage || "")));
      } else {
        toast.success(successmessage);
      }
      // Clear the message parameter from the URL
      navigate({ search: "" });
    } else if (errormessage) {
      toast.error(capitalize(labelFormatter(errormessage || "")));
      // Clear the error message from the URL
      navigate({ search: "" });
    }
    // eslint-disable-next-line
  }, [location, navigate]);

  const ConnectAPI = (platform) => {
    const body = {
      media_platform: platform,
    };
    // console.log(body);
    axios
      .post(ENDPOINTS.API_FEEDS.generateAuthURI, body, apiHeaderConfig)
      .then((response) => {
        const { auth_uri } = response.data;
        window.location.href = auth_uri;
      })
      .catch((error) => {
        // console.error("Error generating authentication URI:", error);
      })
      .finally(() => {
        closeModal();
      });
  };

  const closeModal = () => {
    setIsChecked(false);
    setModalOpen(false);
    clearForm();
    setPerformanceMatrixOpen(false);
  };

  const openModal = (platform, event) => {
    event.stopPropagation();
    setSelectedPlatform(platform);
    setFormData((prevFormData) => ({
      ...prevFormData,
      media_platform: platform,
    }));
    setModalOpen(true);
  };

  const openPerformanceMetric = (platform, event) => {
    setTimeout(() => navigate(`/api/performance-metric/${platform}`), 500);
  };

  useEffect(() => {
    const { token, description, start_date, end_date } = formData;
    if (token && description && start_date && end_date) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  const clearForm = () => {
    setFormData({
      media_platform: "",
      token: "",
      performance_report_name: "",
      description: "",
      start_date: "",
      end_date: "",
    });
  };

  const PlatformRow = ({
    platform,
    buttonClass,
    icon,
    iconType = "fa",
    iconSrc,
    modalHandler,
    performanceHandler,
    description,
    disabled = false,
  }) => (
    <div className="row text-white py-2 d-flex align-items-center border-bottom">
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <button
          className={`btn ${buttonClass} api-button d-flex align-items-center`}
          onClick={(event) => modalHandler(platform, event)}
          style={{ width: "225px" }}
          disabled={disabled}
        >
          {iconType === "fa" && (
            <i className={`${icon} me-2`} aria-hidden="true" />
          )}
          {iconType === "img" && (
            <img
              src={iconSrc}
              alt={`${platform.toLowerCase()}_logo`}
              className="me-2"
              style={{ height: "20px", width: "20px" }}
            />
          )}
          Sign in with {platform}
        </button>
      </div>
      <div className="col-md-2 d-flex align-items-center justify-content-center">
        <button
          className="btn btn-secondary"
          onClick={(event) => performanceHandler(platform, event)}
          disabled={disabled}
        >
          <i className="bi bi-gear"></i>
        </button>
      </div>
      <div className="col-md-6 text-justify">
        <p style={{ textAlign: "justify" }}>{description}</p>
      </div>
    </div>
  );

  return (
    <div
      className="container-fluid position-relative z-index-1 pb-5"
      onScroll={props.handleScroll}
      style={{ top: "58px" }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="row text-white mt-4 d-flex align-items-center justify-content-center border-bottom ">
            <div className="col-md-4 d-flex justify-content-center">
              <h6 className="mb-2">Network</h6>
            </div>
            <div className="col-md-2 d-flex justify-content-center">
              <h6 className="mb-2 mx-2 align-items-center">
                Performance Metric
              </h6>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <h6 className="mb-2 mx-2">Description</h6>
            </div>
          </div>

          <PlatformRow
            platform="Meta"
            buttonClass="btn-primary"
            icon="fa-brands fa-meta mt-1 me-2"
            modalHandler={async (platform, event) => {
              event.stopPropagation();

              // Fetch Meta accounts
              const accounts_data = await fetchAccounts();
              // Check if accounts exist
              if (accounts_data && Object.keys(accounts_data).length > 0) {
                setShowMetaPopup(true); // Show the MetaPopup if accounts exist
              } else {
                ConnectAPI(platform); // Otherwise, call ConnectAPI
              }
            }}
            performanceHandler={openPerformanceMetric}
            description="Please ensure that you have an ad account associated with the Facebook profile that you're logging in with."
          />
          <PlatformRow
            platform="Google"
            buttonClass="btn-light"
            iconType="img"
            iconSrc="https://cdn.iconscout.com/icon/free/png-256/free-google-160-189824.png"
            modalHandler={openModal}
            performanceHandler={openPerformanceMetric}
            description="Please ensure that you have an ad account associated with the Google profile that you're logging in with."
          />
          <PlatformRow
            platform="TradeDesk"
            buttonClass="btn-dark"
            iconType="img"
            iconSrc={whiteLabel.TradeDesk_logo}
            modalHandler={openModal}
            performanceHandler={openPerformanceMetric}
            description="Please ensure that you have an ad account associated with the Trade Desk profile that you're logging in with."
          />
          <PlatformRow
            platform="Reddit"
            buttonClass="btn-danger"
            icon="bi bi-reddit me-2 fs-5"
            modalHandler={ConnectAPI}
            performanceHandler={openPerformanceMetric}
            description="Please ensure that you have an ad account associated with the Reddit profile that you're logging in with."
          />
          <PlatformRow
            platform="Linkedin"
            buttonClass="btn-primary"
            icon="bi bi-linkedin text-light me-2 fs-5"
            modalHandler={ConnectAPI}
            performanceHandler={openPerformanceMetric}
            description="Please ensure that you have an ad account associated with the LinkedIn profile that you're logging in with."
          />
          <PlatformRow
            platform="Bing"
            buttonClass="btn-light"
            icon="bi bi-bing text-info me-2 fs-5"
            modalHandler={ConnectAPI}
            performanceHandler={openPerformanceMetric}
            description="Please ensure that you have an ad account associated with the Bing profile that you're logging in with."
            disabled={true}
          />
          {performanceMatrixOpen && (
            <PerformanceMetric
              show={performanceMatrixOpen}
              platform={SelectedPlatform}
              onHide={closeModal}
            />
          )}
          {SelectedPlatform === "TradeDesk" && (
            <TradeDeskPopup
              SelectedPlatform={SelectedPlatform}
              formData={formData}
              setFormData={setFormData}
              isFormValid={isFormValid}
              modalOpen={modalOpen}
              closeModal={closeModal}
            />
          )}
          {SelectedPlatform === "Google" && (
            <GooglePopup
              SelectedPlatform={SelectedPlatform}
              ConnectAPI={ConnectAPI}
              modalOpen={modalOpen}
              closeModal={closeModal}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              props={props}
            />
          )}
         {showMetaPopup && <MetaPopup accountsData={accountsData} ConnectAPI={ConnectAPI} setShowMetaPopup={setShowMetaPopup} />}
        </>
      )}
    </div>
  );
}

export default Dashboard_API;
