import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import BusinessAdaccounts from "./BusinessAdaccounts";
import BusinessPrimaryPage from "./BusinessPrimaryPage";
import BusinessPages from "./BusinessPages";
import { highlightText } from "../../utils/MetaPopup_Helper";

const BusinessSection = ({
  data,
  formData,
  setFormData,
  toggleSelectAll,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openAccordion, setOpenAccordion] = useState([]);
  const firstMatchRef = useRef(null); // Reference for the first match

  // Helper function to get nested data
  const getNestedData = (business, dataType) => {
    return business[dataType] ? business[dataType] : [];
  };

  const filterBusinesses = (businesses, query) => {
    return businesses.filter((business) => {
      const matchesName =
        business.name &&
        business.name.toLowerCase().includes(query.toLowerCase());
      const matchesId = business.id && business.id.toString().includes(query);

      const adaccounts = getNestedData(business, "ad_accounts");
      const primaryPage = getNestedData(business, "primary_page");
      const pages = getNestedData(business, "pages");

      const instagramMatch = (account) =>
        (account &&
          account.username &&
          account.username.toLowerCase().includes(query.toLowerCase())) ||
        (account.id && account.id.toString().includes(query));

      const adaccountMatch =
        adaccounts &&
        adaccounts.some(
          (adaccount) =>
            (adaccount.name &&
              adaccount.name.toLowerCase().includes(query.toLowerCase())) ||
            (adaccount.id && adaccount.id.toString().includes(query))
        );

      const primaryPageMatch =
        (primaryPage &&
          ((primaryPage.name &&
            primaryPage.name.toLowerCase().includes(query.toLowerCase())) ||
            (primaryPage.id && primaryPage.id.toString().includes(query)))) ||
        (primaryPage.connected_instagram_account &&
          instagramMatch(primaryPage.connected_instagram_account));

      const pagesMatch =
        pages &&
        pages.some(
          (page) =>
            (page.name &&
              page.name.toLowerCase().includes(query.toLowerCase())) ||
            (page.id && page.id.toString().includes(query)) ||
            (page.connected_instagram_account &&
              instagramMatch(page.connected_instagram_account))
        );

      return (
        matchesName ||
        matchesId ||
        adaccountMatch ||
        primaryPageMatch ||
        pagesMatch
      );
    });
  };

  useEffect(() => {
    const matchingBusinessIds = filterBusinesses(
      data.businesses,
      searchQuery
    ).map((business) => business.id);
    if (searchQuery) {
      setOpenAccordion(matchingBusinessIds); // Open matching businesses
      if (firstMatchRef.current) {
        firstMatchRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      setOpenAccordion([]); // Close all accordions if no query
    }
    // eslint-disable-next-line
  }, [searchQuery, data.businesses]);

  const handleAccordionToggle = (eventKey) => {
    setOpenAccordion((prev) =>
      prev.includes(eventKey)
        ? prev.filter((key) => key !== eventKey)
        : [...prev, eventKey]
    );
  };

  return (
    <>
      <div className="businesses">
        <input
            type="text"
            className="form-control mb-2"
            placeholder="Search by name or ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
      </div>

      <Accordion activeKey={openAccordion.length > 0 ? openAccordion : null}>
        {filterBusinesses(data.businesses, searchQuery).map(
          (business, index) => (
            <Accordion.Item key={business.id} eventKey={business.id}>
              <Accordion.Header
                onClick={() => handleAccordionToggle(business.id)}
                ref={index === 0 ? firstMatchRef : null} // Attach ref to first match
              >
                <div className="d-flex justify-content-between w-100">
                  <label
                    className="form-check-label"
                    htmlFor={`business-${business.id}`}
                  >
                    {highlightText(business.name, searchQuery)}{" "}
                    {/* Highlight matched text */}
                  </label>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <BusinessAdaccounts
                  business={business}
                  formData={formData}
                  setFormData={setFormData}
                  getNestedData={getNestedData}
                  toggleSelectAll={toggleSelectAll}
                  highlightText={(text) => highlightText(text, searchQuery)}
                />
                <hr />
                <BusinessPrimaryPage
                  business={business}
                  formData={formData}
                  setFormData={setFormData}
                  highlightText={(text) => highlightText(text, searchQuery)}
                />
                <hr />
                <BusinessPages
                  business={business}
                  formData={formData}
                  setFormData={setFormData}
                  getNestedData={getNestedData}
                  toggleSelectAll={toggleSelectAll}
                  highlightText={(text) => highlightText(text, searchQuery)}
                />
              </Accordion.Body>
            </Accordion.Item>
          )
        )}
      </Accordion>
    </>
  );
};

export default BusinessSection;
