import { combineReducers } from "redux";
import {
  SET_HEATMAP_ON,
  SET_SIZE_FACTOR,
  SET_VIEW_TYPE,
  SET_START_DATE,
  SET_END_DATE,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_PLATFORM,
  SET_SELECTED_TYPE,
  SET_SELECTED_STATUS,
  SET_SELECTED_PERFORMANCE,
  SET_SELECTED_SORT_BY,
  SET_ID,
  SET_SEARCH_TEXT,
  RESET_FILTERS,
  SET_SUBPLATFORM_DATA,
  SET_USER,
} from "./actions";

const today = new Date();
const startdate = new Date(today);
startdate.setDate(today.getDate() - 30);

const initialState = {
  startDate: sessionStorage.getItem("startDate") || startdate,
  endDate: sessionStorage.getItem("endDate") || today,
  selectedCategory: sessionStorage.getItem("selectedCategory")?.split(",") || [
    "Organic",
    "Paid",
  ],
  selectedPlatform: sessionStorage.getItem("selectedPlatform")?.split(",") || [
    "Facebook",
    "Instagram",
    "Reddit",
    "Google_Ads",
    "Google_Analytics",
    "Linkedin",
    "TradeDesk",
    "Non_Digital",
    "Youtube",
  ],
  selectedType: sessionStorage.getItem("selectedType")?.split(",") || [
    "Image",
    "Video",
    "Audio",
    "PDF",
    "Blog",
    "Document",
    "Link",
    "Album",
    "Event",
  ],
  selectedStatus: sessionStorage.getItem("selectedStatus")?.split(",") || [
    "Active",
    "Available",
    "Enabled",
    "Published",
    "Public",
  ],
  selectedPerformance: sessionStorage.getItem("selectedPerformance")
    ? sessionStorage.getItem("selectedPerformance").split(",").map(Number)
    : [5, 4, 3, 2, 1, 0],
  selectedSortBy: sessionStorage.getItem("selectedSortBy") || "Latest",
  sortByperformance: "",
  heatmapOn:
    sessionStorage.getItem("heatmapOn") !== null
      ? JSON.parse(sessionStorage.getItem("heatmapOn"))
      : true,
  sizeFactor:
    sessionStorage.getItem("sizeFactor") !== null
      ? JSON.parse(sessionStorage.getItem("sizeFactor"))
      : 12,
  id: sessionStorage.getItem("id") || "",
  viewType: sessionStorage.getItem("viewType") || "Hub View",
  searchText: "",
  subPlatformData: JSON.parse(sessionStorage.getItem("subPlatformData")) || {},
  user: JSON.parse(localStorage.getItem("user")) || null,
};

const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_START_DATE:
      const newStartDate = action.payload;
      sessionStorage.setItem("startDate", newStartDate);
      return {
        ...state,
        startDate: newStartDate,
      };
    case SET_END_DATE:
      const newEndDate = action.payload;
      sessionStorage.setItem("endDate", newEndDate);
      return {
        ...state,
        endDate: newEndDate,
      };
    case SET_SELECTED_CATEGORY:
      const { category, fromCheckbox } = action.payload;
      let newSelectedCategory;

      if (state.selectedCategory.includes(category)) {
        if (fromCheckbox) {
          newSelectedCategory = state.selectedCategory.filter(
            (item) => item !== category
          );
        } else {
          // If the change is not from checkbox, don't remove the category
          newSelectedCategory = state.selectedCategory;
        }
      } else {
        newSelectedCategory = [...state.selectedCategory, category];
      }
      sessionStorage.setItem("selectedCategory", newSelectedCategory.join(","));
      return {
        ...state,
        selectedCategory: newSelectedCategory,
      };
    case SET_SELECTED_PLATFORM:
      const { platform: selectedPlatform, fromCheckbox: platformFromCheckbox } =
        action.payload;
      let newSelectedPlatform;

      if (state.selectedPlatform.includes(selectedPlatform)) {
        if (platformFromCheckbox) {
          newSelectedPlatform = state.selectedPlatform.filter(
            (item) => item !== selectedPlatform
          );
        } else {
          // If the change is not from checkbox, don't remove the platform
          newSelectedPlatform = state.selectedPlatform;
        }
      } else {
        newSelectedPlatform = [...state.selectedPlatform, selectedPlatform];
      }
      sessionStorage.setItem("selectedPlatform", newSelectedPlatform.join(","));
      return {
        ...state,
        selectedPlatform: newSelectedPlatform,
      };
    case SET_SELECTED_STATUS:
      const { status: selectedStatus, fromCheckbox: statusFromCheckbox } =
        action.payload;
      let newSelectedStatus;

      if (state.selectedStatus.includes(selectedStatus)) {
        if (statusFromCheckbox) {
          newSelectedStatus = state.selectedStatus.filter(
            (item) => item !== selectedStatus
          );
        } else {
          // If the change is not from checkbox, don't remove the status
          newSelectedStatus = state.selectedStatus;
        }
      } else {
        newSelectedStatus = [...state.selectedStatus, selectedStatus];
      }
      sessionStorage.setItem("selectedStatus", newSelectedStatus.join(","));
      return {
        ...state,
        selectedStatus: newSelectedStatus,
      };
    case SET_SELECTED_TYPE:
      const { type: selectedType, fromCheckbox: typeFromCheckbox } =
        action.payload;
      let newSelectedType;

      if (state.selectedType.includes(selectedType)) {
        if (typeFromCheckbox) {
          newSelectedType = state.selectedType.filter(
            (item) => item !== selectedType
          );
        } else {
          // If the change is not from checkbox, don't remove the type
          newSelectedType = state.selectedType;
        }
      } else {
        newSelectedType = [...state.selectedType, selectedType];
      }
      sessionStorage.setItem("selectedType", newSelectedType.join(","));
      return {
        ...state,
        selectedType: newSelectedType,
      };
    case SET_SELECTED_PERFORMANCE:
      const {
        performance_value: selectedPerformance,
        fromCheckbox: shadeFromCheckbox,
      } = action.payload;
      let newSelectedShade;

      if (state.selectedPerformance.includes(selectedPerformance)) {
        if (shadeFromCheckbox) {
          newSelectedShade = state.selectedPerformance.filter(
            (item) => item !== selectedPerformance
          );
        } else {
          // If the change is not from checkbox, don't remove the performance
          newSelectedShade = state.selectedPerformance;
        }
      } else {
        newSelectedShade = [...state.selectedPerformance, selectedPerformance];
      }
      sessionStorage.setItem("selectedPerformance", newSelectedShade.join(","));
      return {
        ...state,
        selectedPerformance: newSelectedShade,
      };
    case SET_SELECTED_SORT_BY:
      const newSelectedSortBy = action.payload; // This will be the new selected sort value
      sessionStorage.setItem("selectedSortBy", newSelectedSortBy);
      return {
        ...state,
        selectedSortBy: newSelectedSortBy,
      };
    case SET_HEATMAP_ON:
      sessionStorage.setItem("heatmapOn", action.payload);
      return {
        ...state,
        heatmapOn: action.payload,
      };
    case SET_SIZE_FACTOR:
      sessionStorage.setItem("sizeFactor", action.payload);
      return {
        ...state,
        sizeFactor: action.payload,
      };
    case SET_VIEW_TYPE:
      sessionStorage.setItem("viewType", action.payload);
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_ID:
      const new_id = action.payload;
      sessionStorage.setItem("id", new_id);
      return {
        ...state,
        id: new_id,
      };
    case SET_SEARCH_TEXT:
      const new_text = action.payload;
      sessionStorage.setItem("searchtext", new_text);
      return {
        ...state,
        searchText: action.payload,
      };
    case SET_SUBPLATFORM_DATA:
      const new_subPlatformData = action.payload;
      sessionStorage.setItem(
        "subPlatformData",
        JSON.stringify(new_subPlatformData)
      );
      return {
        ...state,
        subPlatformData: new_subPlatformData,
      };
    case SET_USER:
      const user = action.payload;
      localStorage.setItem("user", JSON.stringify(user));
      return {
        ...state,
        user: user,
      };
    case RESET_FILTERS:
      sessionStorage.removeItem("startDate");
      sessionStorage.removeItem("endDate");
      sessionStorage.removeItem("selectedCategory");
      sessionStorage.removeItem("selectedPlatform");
      sessionStorage.removeItem("selectedType");
      sessionStorage.removeItem("selectedStatus");
      sessionStorage.removeItem("selectedPerformance");
      sessionStorage.removeItem("searchtext");
      sessionStorage.removeItem("selectedSortBy")
      return {
        ...state,
        startDate: startdate,
        endDate: today,
        selectedCategory: ["Organic", "Paid"],
        selectedPlatform: [
          "Facebook",
          "Instagram",
          "Google_Ads",
          "Google_Analytics",
          "Linkedin",
          "Reddit",
          "TradeDesk",
          "Non_Digital",
          "Youtube",
        ],
        selectedType: [
          "Image",
          "Video",
          "Audio",
          "PDF",
          "Blog",
          "Document",
          "Link",
          "Album",
          "Event",
        ],
        selectedStatus: [
          "Active",
          "Available",
          "Enabled",
          "Published",
          "Public",
        ],
        selectedPerformance: [5, 4, 3, 2, 1, 0],
        selectedSortBy: "Latest",
        searchText: "",
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  assets: assetReducer,
});

export default rootReducer;
