import React, { useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

const PopoverWithHelpIcon = ({ setShowRepluginWarning }) => {
  const [showPopover, setShowPopover] = useState(false);

  const popoverContent = (
    <Popover id="popover-basic" style={{maxWidth: '400px',}}>
      <Popover.Body style={{
          whiteSpace: 'nowrap', // Prevent text wrapping
        }}>
        <div>Can't find your Businesses/Ad Accounts/Pages?</div>
        <div>
          <button
            className="btn-link p-0 border-0"
            style={{ cursor: "pointer", color:"blue",background:"none" }}
            onClick={(e) => {
              e.preventDefault();
              setShowRepluginWarning(true); // Call the function when the link is clicked
            }}
          >
        Click here
          </button>
          {" "} to replugin
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div
    onMouseEnter={() => setShowPopover(true)}
    onMouseLeave={() => setShowPopover(false)}
  >
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="bottom"
      overlay={popoverContent}
      show={showPopover} // Only show popover when state is true
    >
      <div className="d-flex align-items-center fs-6" style={{cursor: "pointer" }}>
      <span className="text-primary mb-1">Help</span>
        <i
          className="bi bi-question-circle-fill text-primary ms-1"
        ></i>
      </div>
    </OverlayTrigger>
  </div>
  
  );
};

export default PopoverWithHelpIcon;
