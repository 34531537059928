const EMPTY_FUNC = () => {};
const EMPTY_OBJECT = {};

const SEARCH_DATE_FORMAT = 'YYYY-MM-DD';
const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
const DISPLAY_DATE_WITH_DAY = 'DD MMM YYYY';
const FULL_GMT_DISPLAY_DATE_FORMAT = "ddd, DD MMM YYYY HH:mm:ss [GMT]"
const MESSAGES = [
    "Retrieving Assets",
    "Analyzing Performance",
    "Sorting Assets",
    "Visualizing Insights",
  ];

export { EMPTY_FUNC, EMPTY_OBJECT, SEARCH_DATE_FORMAT, DISPLAY_DATE_FORMAT, DISPLAY_DATE_WITH_DAY, FULL_GMT_DISPLAY_DATE_FORMAT, MESSAGES };
